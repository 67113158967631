
<mat-card>
  <mat-card-header>
    <mat-card-title>
<h1>Manual de instrucciones</h1> </mat-card-title>
  </mat-card-header>
  </mat-card>

      <mat-card>
        <mat-card-content class="lateral">
            <p align="center" style="text-align: center;"><span style="text-decoration: underline;"><span size="3" style="font-size: medium;"><b>Perfumesfindecurso</b></span></span></p>
    <p align="center" style="text-align: center;"><span style="text-decoration: underline;"><span size="3" style="font-size: medium;">Manual del usuario del coordinador</span></span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">NOTA IMPORTANTE SOBRE LA VERSIÓN DE LA APLICACIÓN:</span></p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Está disponible una aplicación de escritorio, que funciona a través del navegador, y también disponemos apps para móvil, tanto para Android como para IOS.</span></p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">En el caso de querer utilizar el dispositivo móvil, recomendamos encarecidamente que se utilice la versión de móvil pertinente, ya sea Android o IOS. </span></p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;"><span style="text-decoration: underline;">Desaconsejamos el uso de la versión escritorio desde un dispositivo móvil</span></span><span size="3" style="font-size: medium;"> ya que, aun y habiéndose previsto su uso, las condiciones de accesibilidad son menos prácticas que accediendo desde un ordenador personal.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Para participar en nuestras campañas de productos, es preciso que inicialmente el coordinador lo solicite por email. Acto seguido, </span><span size="3" style="font-size: medium;"><b>se enviara un email al coordinador en el que se le facilitarán unas credenciales de acceso al sistema y también una clave privada de 7 dígitos. Esta clave privada es una clave única del coordinador.</b></span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Una vez obtenidas las credenciales y la clave privada, nos dirigiremos a la URL de inicio de la aplicación:</span></p>
    <p style="text-align: justify;"><span color="#0563c1" style="color: #0563c1;"><span style="text-decoration: underline;"><span size="3" style="font-size: medium;">https://www.perfumesfindecurso.es/app/options</span></span></span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <ol style="text-align: justify;">
    <li>
    <p><span size="3" style="font-size: medium;">Login y acceso al sistema</span></p>
    </li>
    </ol>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Una vez allí haremos clic en la opción “Inicio de Coordinador”, donde introduciremos las credenciales que nos habrá facilitado el departamento comercial de Perfumesfindecurso.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Tras hacer login, vemos que nos aparece la pantalla inicio, mostrando una lista con los últimos pedidos, que bien pueden ser pedidos tramitados o estar pendientes de tramitación por parte del coordinador.</span></p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;"><span style="text-decoration: underline;">En este apartado no es preciso hacer nada, ya que tan solo se trata de un resumen informativo.</span></span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;"><span style="text-decoration: underline;"><b>PRIMEROS PASOS PARA EMPEZAR UNA CAMPAÑA DE VENTA DE PRODUCTOS</b></span></span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Lo primero que debemos hacer es crear un curso. Normalmente será el curso que está cursando el grupo de alumnos que va a participar en la venta.</span></p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Para ello nos dirigiremos a la opción “Administrar alumnos” del menú.</span></p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Una vez allí, haremos clic en “Agregar curso”, e indicaremos el nombre que deseemos. (4ESO, 2doBachillerato, Grupoprivado, etc..)</span></p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Una vez dado de alta el curso, ya podemos facilitar la clave privada de 7 digitos a todos los alumnos, con la cual podrán registrarse ellos solos. (Recordemos que esta clave privada es una clave única del coordinador, y que también se facilita junto con las credenciales en el momento de solicitar el alta)</span></p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">A partir de aquí, los alumnos podrán registrarse utilizando esta clave privada de 7 dígitos. Como es una clave única del coordinador, al introducirla el sistema ya les mostrara el nombre de su centro así como el de su coordinador.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Acto seguido completaran el registro rellenando todos los campos. Ellos mismos pueden crear su propia contraseña al registrarse, e igualmente recibirán un correo de confirmación en el que también se les mostrará el usuario y contraseña. (el usuario será la dirección de correo electrónico que habrán introducido en el registro)</span></p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Una vez hecho esto, el alumno podrá empezar a confeccionar su propio pedido, y su coordinador podrá ver su pedido con las últimas modificaciones (siempre que el alumno haya guardado los cambios) en el apartado “Administrar pedidos”</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span color="#2f5496" style="color: #2f5496;"><span size="3" style="font-size: medium;"><span style="text-decoration: underline;">Véase Manual de instrucciones del alumno</span></span></span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <ol start="2" style="text-align: justify;">
    <li>
    <p><span size="3" style="font-size: medium;">Administrar pedidos</span></p>
    </li>
    </ol>
    <p style="text-align: justify;">&nbsp;</p>
    <ul style="text-align: justify;">
    <li>
    <p><span size="3" style="font-size: medium;"><span style="text-decoration: underline;">Agregar pedidos y hacer la compra.</span></span></p>
    </li>
    </ul>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Esta es la opción del menú más utilizada en el caso del coordinador, puesto que desde ella se procede a la validación de los pedidos que han cursado los alumnos. </span></p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Importante asegurarse de que los alumnos no tienen unidades de productos pendientes de agregar o añadir a su pedido, para lo cual </span><span size="3" style="font-size: medium;"><span style="text-decoration: underline;">se recomienda establecer una fecha límite para realizar y modificar los pedidos.</span></span></p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Al entrar en esta opción de “Administrar pedidos”, aparecerá una lista con los pedidos pendientes de validación por parte del coordinador.</span></p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Cada fila es el pedido total de un alumno.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Llegada esa fecha límite acordada con sus alumnos, el coordinador podrá disponerse a realizar la compra conjunta de todos los pedidos de sus alumnos.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">El coordinador deberá agregar todos los pedidos que desee unificar en un pedido conjunto, para posteriormente tramitarlo.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Lo hará mediante el botón “agregar” disponible a la derecha de cada pedido.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Una vez agregado un pedido, el mismo se moverá de la parte inferior hacia la superior.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Cuando el coordinador haya agregado todos los pedidos, para realizar la compra definitiva y conjunta de todos esos pedidos, deberá hacer clic en el botón “Hacer compra”.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Una vez hecho clic en el botón “Hacer compra”, el pedido será enviado a Perfumesfindecurso para su tramitación en las condiciones ofertadas y no podrán deshacerse los cambios.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">No obstante, en el caso de que el coordinador sea consciente de la existencia de algún error en el pedido tramitado, lo que hará será contactar a Perfumesfindecurso para notificarlo. En este caso, enviaremos un email a </span><span color="#0563c1" style="color: #0563c1;"><span style="text-decoration: underline;"><a href="mailto:pedidosweb@perfumesfindecurso.es"><span size="3" style="font-size: medium;">pedidosweb@perfumesfindecurso.es</span></a></span></span><span size="3" style="font-size: medium;"> informando del error para que ellos puedan corregirlo y enviar el pedido de la forma deseada.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <ul style="text-align: justify;">
    <li>
    <p><span size="3" style="font-size: medium;"><span style="text-decoration: underline;">Editar y eliminar pedidos</span></span></p>
    </li>
    </ul>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">En el caso que necesitemos modificar o incluso eliminar el pedido de un alumno, también es posible hacerlo des de la opción del menú “Administrar pedidos”.</span></p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Primero de todo haremos clic en el botón “Editar pedido”, que es el botón de la derecha de los 3 que hay en cada fila o pedido.</span></p>
    <p style="text-align: justify;"><a name="_GoBack"></a> <span size="3" style="font-size: medium;">Una vez hecho clic en “Editar pedido” veremos que accedemos al pedido del alumno con las mismas opciones que tiene el en su panel de control, y las cuales nos permiten quitar productos, cambiar cantidades y, si lo deseamos, cancelar el pedido completo. </span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <ol start="3" style="text-align: justify;">
    <li>
    <p><span size="3" style="font-size: medium;">Historial de compras</span></p>
    </li>
    </ol>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Mediante esta opción el coordinador puede acceder con todo detalle a todos los pedidos validados (que ya se han enviado a Perfumesfindecurso para su tramitación)</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Podrá imprimir informes y podrá acceder, a través de los botones “detalle” a los detalles de todos los pedidos.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Además de otras informaciones, en este informe aparecen todos los pedidos individualizados por alumno, lo cual permite imprimirlo si se desea, para entregarlo a cada alumno junto con todos los productos que ha solicitado, de forma que tanto coordinador como alumnos pueden verificar que la entrega de productos coincida con el pedido que el alumno ha realizado.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <ol start="4" style="text-align: justify;">
    <li>
    <p><span size="3" style="font-size: medium;">Administrar alumnos</span></p>
    </li>
    </ol>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Esta opción, el cual la primera vez que accedemos nos sirve para dar de alta el curso, nos sirve también para gestionar los alumnos que se han registrado a dicho curso.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Podremos añadir alumnos manualmente, eliminarlos, o modificar cualquier dato del registro, incluidos las credenciales.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">También existe la opción de deshabilitar a ese alumno, de forma que no podrá realizar más cambios ni más pedidos hasta que el coordinador vuelva a habilitarlo haciendo clic en el mismo botón.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <ol start="5" style="text-align: justify;">
    <li>
    <p><span size="3" style="font-size: medium;">Cambio de contraseña</span></p>
    </li>
    </ol>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Como su nombre indica, esta opción nos permite cambiar nuestra contraseña.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <ol start="6" style="text-align: justify;">
    <li>
    <p><span size="3" style="font-size: medium;">Cerrar sesión</span></p>
    </li>
    </ol>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Podemos cerrar la sesión mediante este botón. Transcurridos 60 minutos de inactividad, la sesión también se cerrará automáticamente.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Para cualquier incidencia o consulta con respecto a la aplicación, pueden dirigirse a </span><span color="#0563c1" style="color: #0563c1;"><span style="text-decoration: underline;"><a href="mailto:pedidosweb@perfumesfindecurso.es"><span size="3" style="font-size: medium;">pedidosweb@perfumesfindecurso.es</span></a></span></span><span size="3" style="font-size: medium;">, o bien llamando al teléfono móvil 626759971.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;">&nbsp;</p>
    <p align="center" style="text-align: center;"><span style="text-decoration: underline;"><span size="3" style="font-size: medium;"><b>Perfumesfindecurso</b></span></span></p>
    <p align="center" style="text-align: center;"><span style="text-decoration: underline;"><span size="3" style="font-size: medium;">Manual del usuario del alumno</span></span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">NOTA IMPORTANTE SOBRE LA VERSIÓN DE LA APLICACIÓN:</span></p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Está disponible una aplicación de escritorio, que funciona a través del navegador, y también disponemos apps para móvil, tanto para Android como para IOS.</span></p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">En el caso de querer utilizar el dispositivo móvil, recomendamos encarecidamente que se utilice la versión de móvil pertinente, ya sea Android o IOS. </span></p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;"><span style="text-decoration: underline;">Desaconsejamos el uso de la versión escritorio desde un dispositivo móvil</span></span><span size="3" style="font-size: medium;"> ya que, aun y habiéndose previsto su uso, las condiciones de accesibilidad son menos prácticas que accediendo desde un ordenador personal.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Para empezar nos dirigiremos a esta URL:</span></p>
    <p style="text-align: justify;"><span color="#0563c1" style="color: #0563c1;"><span style="text-decoration: underline;"><span size="3" style="font-size: medium;">https://www.perfumesfindecurso.es/app/options</span></span></span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <ol style="text-align: justify;">
    <li>
    <p><span size="3" style="font-size: medium;">Registro del alumno</span></p>
    </li>
    </ol>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Lo primero que debemos hacer para empezar es registrarnos en la aplicación, con lo cual obtendremos las credenciales (usuario y contraseña) para poder acceder al sistema y crear y modificar nuestros pedidos.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Para poder registrarnos, el coordinador o tutor, nos debe facilitar una clave única.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Cuando accedemos al registro del alumno, lo primero que nos pedirá será esta clave.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Si la clave es correcta, entonces nos permitirá introducir todos los datos necesarios para el registro.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Es importante rellenar bien el formulario de registro. </span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Lo primero será escoger el curso de la lista desplegable, que será el curso que el coordinador haya creado previamente.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Los requisitos para la contraseña son que debe contener como mínimo 1 letra mayúscula, 1 letra minúscula y 1 numero, y la longitud mínima es de 8 dígitos.</span></p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Si se desea se pueden utilizar símbolos.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Una vez aceptados los términos y condiciones, podemos hacer clic en registrar y si hemos introducido los datos de forma correcta, se creará el usuario y acto seguido se enviara un email al alumno confirmándole sus credenciales.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">A partir de aquí ya podemos acceder al panel de control del alumno, a través del menú de principal de la aplicación, ya sea de escritorio o de móvil.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <ol start="2" style="text-align: justify;">
    <li>
    <p><span size="3" style="font-size: medium;">Login y acceso al sistema</span></p>
    </li>
    </ol>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Para acceder al sistema como alumno, deberemos hacer clic en la opción “Inicio alumno” del menú principal. Tras introducir nuestras credenciales accederemos a la pantalla de inicio, donde se nos muestra información general acerca de las ventas y beneficios obtenidos por nuestros pedidos ya cerrados.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Tanto para realizar un nuevo pedido, como para modificar el pedido que tengamos en curso, accederemos a la opción “Realizar pedido” del menú.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <ol start="3" style="text-align: justify;">
    <li>
    <p><span size="3" style="font-size: medium;">Realizar pedido</span></p>
    </li>
    </ol>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">En el caso de disponer ya productos en la cesta, el sistema siempre nos muestra un mensaje informativo de que hay un pedido pendiente de validación por parte del profesor. </span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Este mensaje es habitual y se mostrara a menudo para recordarnos que nuestro pedido sigue abierto para seguir incrementándolo y modificándolo, pues aún no ha sido validado/cerrado por el profesor.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Podréis añadir productos, quitarlos o modificar sus cantidades. Recordad siempre guardar los cambios mediante el botón “Guardar cambios”.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">El funcionamiento habitual, es que el coordinador establecerá una fecha límite para que confeccionéis vuestro pedido. Llegada esa fecha el validara todos los pedidos y estos ya no podrán modificarse.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Cuando el coordinador haya validado vuestro pedido, este ya se encontrará en proceso de preparación y envío junto con otros pedidos de otros alumnos, y veréis que vuestra cesta aparece vacía de nuevo.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">El coordinador os avisará llegado el momento para acordar la entrega de vuestros productos.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Previo acuerdo con el coordinador, también podréis empezar a confeccionar un nuevo pedido.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <ol start="4" style="text-align: justify;">
    <li>
    <p><span size="3" style="font-size: medium;">Historial</span></p>
    </li>
    </ol>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">En este apartado podemos consultar los últimos pedidos que han sido validados y cursados por el coordinador.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <ol start="5" style="text-align: justify;">
    <li>
    <p><span size="3" style="font-size: medium;">Cambio de contraseña</span></p>
    </li>
    </ol>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Como su nombre indica, esta opción nos permite cambiar nuestra contraseña.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <ol start="6" style="text-align: justify;">
    <li>
    <p><span size="3" style="font-size: medium;">Cerrar sesión</span></p>
    </li>
    </ol>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Podemos cerrar la sesión mediante este botón. Transcurridos 60 minutos de inactividad, la sesión también se cerrará automáticamente.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Para cualquier incidencia o consulta con respecto a la aplicación, podéis dirigiros a </span><span color="#0563c1" style="color: #0563c1;"><span style="text-decoration: underline;"><a href="mailto:pedidosweb@perfumesfindecurso.es"><span size="3" style="font-size: medium;">pedidosweb@perfumesfindecurso.es</span></a></span></span><span size="3" style="font-size: medium;">. Se intentará solucionar vuestro problema o consulta con la mayor rapidez.</span></p>
  </mat-card-content>
  </mat-card>

