import { Component } from '@angular/core';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss']
})
export class TutorialComponent  {
    pdfSource =  "../assets/aviso_legal.pdf";

    zoom: number = 1.0;
originalSize: boolean = true;

incrementZoom(amount: number) {
    this.zoom += amount;   }

  }

