<div class="row fade-in proviaje-last-item">

<section class="col-lg-6 col-md-12" *ngIf="userType == typeUser">
  <mat-card class="col-12">
    <mat-card-header class="secondary">
      <mat-card-title>
        Total ventas
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <h1 class="card-title text-center float-none font-weight-bold">
        {{ user_profit[0] != null && user_profit[0].total_sellerprice != null ?
          (user_profit[0].total_sellerprice | currency : "EUR" : "symbol") :
          (0 | currency : "EUR" : "symbol") }}
      </h1>
    </mat-card-content>
  </mat-card>
  <div class="text-center p-5 desk-button fourth col-12">
    <button [routerLink]="['/user', 'record']" mat-flat-button color="accent" class="p-3">
      <i class="fas fa-history"></i>
      Historial
    </button>
  </div>
</section>

<section class="col-lg-6 col-md-12" *ngIf="userType == typeUser">
  <mat-card class="col-12">
    <mat-card-header>
      <mat-card-title>
        Total beneficio
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <h1 class="card-title text-center float-none font-weight-bold">
        {{ user_profit[0] != null && user_profit[0].total_sellerprice != null && user_profit[0].total_saleprice != null ?
          (user_profit[0].total_sellerprice - user_profit[0].total_saleprice | currency : "EUR" : "symbol") :
          (0 | currency : "EUR" : "symbol") }}
      </h1>
    </mat-card-content>
  </mat-card>
  <div class="text-center p-5 desk-button third col-12">
    <button [routerLink]="['/user', 'order']"
            mat-flat-button
            color="primary"
            class="p-3">
      <i class="fas fa-edit"></i>
      Hacer/Modificar pedido
    </button>
  </div>
</section>

<!--Moderator-->
<section  class="col-12"
          *ngIf="userType == typeMod">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Ultimos 10 pedidos</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="table-container">
        <app-table-order  [showCourse]="true"
                        [userType]="dataService.getUserType()"
                        [requestList]="requestModList"></app-table-order>
      </div>
    </mat-card-content>
  </mat-card>
</section>

<!--Administrator-->
<section class="col-lg-4 col-md-12 mb-3" *ngIf="userType == typeAdmin">
  <mat-card>
    <mat-card-header class="secondary">
      <mat-card-title>
        Riesgo de stock
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="table-container">
        <app-table-product  [productList]="productAdminList"
                          tableConfig="stock"></app-table-product>
      </div>
    </mat-card-content>
  </mat-card>
</section>
<section  class="col-lg-8 col-md-12"
          *ngIf="userType == typeAdmin">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        Compras recientes
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="table-container">
        <app-table-sales  [showInstitute]="true"
                        [showGrade]="true"
                        [userType]="typeAdmin"
                        [orderList]="orderAdminList"></app-table-sales>
      </div>
    </mat-card-content>
  </mat-card>
</section>
</div>
