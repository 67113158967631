import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataMasterService,USER_TYPE_ADMIN } from 'src/app/services/data-master.service';
import { Router } from '@angular/router';
import { Product } from './../../../classes/product.class';

import Swal from 'sweetalert2';
import * as _ from 'lodash';
import jquery from 'jquery';
import { Category } from '../../../classes/category';

declare var $;

@Component({
  selector: 'app-admin-catalogue',
  templateUrl: './admin-catalogue.component.html',
  styleUrls: ['./admin-catalogue.component.scss']
})
export class AdminCatalogueComponent implements OnInit {
  productList : Product[] = [];
  readonly typeAdmin = USER_TYPE_ADMIN;
  product_name:string;
  product_quantity:number;
  product_saleprice:number;
  product_sellerprice:number;
  product_treshold:number;
  product_validate_generate:boolean =false;
  product_generate:number = 0;
  product_image="imagen";
  product_description : string;
  category_id : number;
  categoryData : Category[] = [];
  newProduct:boolean=false;
  disabledAddButton : boolean = false;
  /*aumentar stock*/
  addStock:number;
  showUpdatestock:boolean=false;
  add_product_stock:number=0;


  /*imagen*/
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;

  constructor(private dataService : DataMasterService, private router : Router ) {
    this.dataService.setFooter("fixed");
    
  }

  ngOnInit(): void {
    this.loadCategoryList();
    this.loadProductList();
  }

  loadProductList() {
    this.dataService.getAllProducts().subscribe(response => {
      if (!response[0]) {
        console.error(response[1]);
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
      } else if (!Array.isArray(response[1])) {
      } else {
        this.productList = response[1];
      }
    });
  }
  loadCategoryList() {
    this.dataService.getAllCategorys().subscribe(response =>{
      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      if (response[1] == "no result") {
        this.categoryData = [];
        this.dataService.showMessage('No existen categorías actualmente, por favor agregue una nueva.', '', 'warning');
        
      } else {
        this.categoryData = response[1];
        console.log(this.categoryData);
      }
    });
  }
  /*imagen*/
  fileChangeEvent(fileInput: any) {
        this.imageError = null;
        if (fileInput.target.files && fileInput.target.files[0]) {
            // Size Filter Bytes
            const max_size = 20971520;
            const allowed_types = ['image/png', 'image/jpeg'];
            const max_height = 2000;
            const max_width = 2000;
            if (fileInput.target.files[0].size > max_size) {
              this.dataService.showMessage('La imagen sobrepasa el tamaño', '', 'error');
                this.imageError =
                    'Sobrepasa el tamaño ' + max_size / 1000 + 'Mb';
                return false;
            }

            if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
              this.dataService.showMessage('Solo se permiten imagenes ( JPG | PNG )', '', 'error');
                this.imageError = 'Solo se permiten imagenes ( JPG | PNG )';
                return false;
            }
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const image = new Image();
                image.src = e.target.result;
                image.onload = rs => {
                    const img_height = rs.currentTarget['height'];
                    const img_width = rs.currentTarget['width'];
                    console.log(img_height, img_width);
                    if (img_height > max_height && img_width > max_width) {
                        this.imageError =
                            'El tamaño maximo es ' +
                            max_height +
                            '*' +
                            max_width +
                            'px';
                        return false;
                    } else {
                        const imgBase64Path = e.target.result;
                        this.cardImageBase64 = imgBase64Path;
                        this.product_image = this.cardImageBase64;
                        this.isImageSaved = true;
                        // this.previewImagePath = imgBase64Path;
                    }
                };
            };
            reader.readAsDataURL(fileInput.target.files[0]);
        }
    }

  removeImage() {
      this.cardImageBase64 = null;
      this.isImageSaved = false;
      $('input[type="file"]').val(null);
  }
  agregar(){
    this.newProduct = true;

    this.product_name = null;
    this.product_quantity = null;
    this.product_saleprice = null;
    this.product_sellerprice = null;
    this.product_treshold = null;
    this.product_validate_generate = false;
    this.product_generate = 0;
    this.product_image="imagen";
    this.product_description = null;
    this.cardImageBase64 = null;
    this.category_id = null;
  }
  cancelar(){
    this.newProduct = false;
  }
  agregarProducto() {
    this.disabledAddButton = true;

    if(this.product_validate_generate == true){
      this.product_generate=1;
    }

    let image : string = (this.product_image.includes('../')) ?
      null : this.product_image;

    this.dataService.regProduct(this.product_name,
      this.category_id,
      this.product_quantity,
      this.product_treshold,
      this.product_generate,
      this.product_sellerprice,
      this.product_saleprice,
      image,
      this.product_description).subscribe(respuesta => {

      this.disabledAddButton = false;
      console.log(respuesta);
      if (respuesta[0] == false){
        this.dataService.showMessage('No se pudo registrar el producto', '', 'error');
        console.log(respuesta);
        this.newProduct = false;
      } else {
        this.loadProductList();
        this.dataService.showMessage('Producto registrado correctamente', '', 'success');
        console.log(respuesta);
        this.newProduct = false;
      }

    });
  }

  updateStock(actual_stock:string,product_id:number){
    this.add_product_stock = this.add_product_stock + parseFloat(actual_stock);
    this.dataService.updateStockProduct(product_id,this.add_product_stock)
      .subscribe(responseUpdateStock=>{
        console.log(responseUpdateStock);
        if(responseUpdateStock[0]== true){
          this.dataService.showMessage('Stock aumentado', '', 'success');
        }else{
          this.dataService.showMessage('No se puedo aumentar el stock', '', 'error');
        }
        this.loadProductList();
      });
    this.addStock = undefined;

    console.log(this.add_product_stock);
  }

  showEditStock(i:number){
    this.addStock = i;
  }
  hideEditStock(){
    this.addStock = undefined;
  }
}
