<mat-card class="mx-auto col-lg-4 col-md-12 col-sm-12 my-5 fade-in">
  <mat-card-header>
    <mat-card-title>Seleccionar curso</mat-card-title>
  </mat-card-header>
  <mat-card-content class="text-center p-2">
    <mat-form-field>
      <mat-label>Cursos</mat-label>
      <mat-select  [(ngModel)]="grade_id" [ngModelOptions]="{standalone: true}">
        <mat-option (click)="getRequestid()" *ngFor="let course of grades" [value]="course.grade_id">{{course.grade_name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card-content>
</mat-card>
<mat-card class="mb-2 fade-in" *ngIf="grade_id">
  <mat-card-header>
    <mat-card-title>Pedidos a tramitar</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="table-container">
      <app-table-course [request_List]="carRequest_list"
                      (deleteFromCar)="deleteFromCar($event)"
                      (generateOrder)="generateOrder()"
                      [disableButtons]="disableButtons"
                      tableConfig="car"></app-table-course>
    </div>
  </mat-card-content>
</mat-card>
<mat-card class="mt-2 fade-i proviaje-last-item" *ngIf="grade_id">
  <mat-card-header>
    <mat-card-title>Pedidos solicitados por el alumno</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="table-container">
      <app-table-course [request_List]="pending_request_list" (addRequest)="addRequest($event)" tableConfig="default"></app-table-course>
    </div>
  </mat-card-content>
</mat-card>
