import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { DataMasterService, USER_TYPE_MOD } from 'src/app/services/data-master.service';
import Swal from 'sweetalert2';
import { Product } from '../../../../../proviajes_app/src/app/classes/product.class';
@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.scss']
})
export class EditCategoryComponent implements OnInit {
  category_name : string;
  category_id : number;
  productData: Product[] = [];
  @ViewChild('categoryForm') categoryForm : FormGroup;

  constructor(private dataService : DataMasterService,
    private activatedRoute : ActivatedRoute, private router : Router) { 
      this.activatedRoute.params.subscribe(params => {
        this.category_id = params.id;
  
  
        //this.dataService.showMessage("Cargando");
        //Swal.showLoading();
        this.loadCategoryData();
        this.loadProductData();
      });
    }

    loadCategoryData() {
      this.dataService.getCategoryById(this.category_id).subscribe(response => {
        if (!response[0]) {
          console.error(response[1]);
          Swal.close();
          this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
          return;
        }
  
        const category = response[1][0];
  
        this.category_name = category.category_name;

      });
    }

    loadProductData() {
      this.dataService.getProductbyCategory(this.category_id).subscribe(response =>{
        if (!response[0]) {
          console.error(response[1]);
          Swal.close();
          this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
          return;
        }
  
        if (response[1] == "no result") {
          this.productData = [];
          this.dataService.showMessage('No existen productos actualmente asociados a la categoría seleccionada.', '', 'warning');
          
        } else {
          this.productData = response[1];
          console.log(this.productData);
        }
      });
    }

    updateCategory() {
      console.log(this.category_name);
      console.log(this.category_id);
      if (this.categoryForm.invalid) {
        this.dataService.showMessage('Campos invalidos', 'Por favor, rellene de forma valida los campos marcados.', 'error');
        return;
      }
      
      this.dataService.updateCategory(this.category_id, this.category_name).subscribe(response =>{
        console.log(response);
        if (response[0] == true) {
          this.dataService.showMessage('Categoría modificada', '', 'success');
          const userType = this.dataService.getUserType();
          this.router.navigate(['/admin', 'admin-categorys']);
        } else {
          this.dataService.showMessage('No se pudo modificar la categoría', '', 'error');
        }
      });
      
    }

  ngOnInit(): void {
  }

}
