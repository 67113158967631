import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DataMasterService } from 'src/app/services/data-master.service';

import { Product } from 'src/app/classes/product.class';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
import jquery from 'jquery';
import { Category } from '../../../classes/category';

declare var $;

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {
  product_id : number;
  product_name : string;
  product_quantity : number;
  product_saleprice : number;
  product_sellerprice : number;
  product_generate : number;
  product_treshold : number;
  product_image : string;
  product_validate_generate : boolean;
  product_description : string;
  category_id : number;
  categoryData : Category[] = [];

  /*imagen*/
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;
  constructor(private dataService : DataMasterService,
    private activatedRoute : ActivatedRoute, private router : Router) {
    this.activatedRoute.params.subscribe(params =>{
      this.product_id = params.id;
    });
    this.loadCategoryList();

   }

  ngOnInit(): void {
    this.dataService.getProduct(this.product_id).subscribe(response =>{
      console.log(response);

      if (!response[0] || !Array.isArray(response[1])) {
        this.dataService.showMessage('No se han cargado productos a la plataforma', '', 'error');
      } else {
        const product = response[1][0];
        this.product_id = product.product_id;
        this.product_name = product.product_name;
        this.product_quantity = product.product_quantity;
        this.product_saleprice = product.product_saleprice;
        this.product_sellerprice = product.product_sellerprice;
        this.product_generate = product.product_generate;
        this.product_treshold = product.product_treshold;
        this.product_image = product.product_image;
        this.cardImageBase64 = product.product_image;
        this.category_id = product.category_id;
        this.product_description = product.product_description;
        this.product_validate_generate = product.product_generate == "1";
      }

    });
  }
  loadCategoryList() {
    this.dataService.getAllCategorys().subscribe(response =>{
      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      if (response[1] == "no result") {
        this.categoryData = [];
        this.dataService.showMessage('No existen categorías actualmente, por favor agregue una nueva.', '', 'warning');
        
      } else {
        this.categoryData = response[1];
        console.log(this.categoryData);
      }
    });
  }

  /*imagen*/
  fileChangeEvent(fileInput : any) {
    this.imageError = null;
    if (fileInput.target.files &&
      fileInput.target.files[0]) {
        // Size Filter Bytes
        const max_size = 20971520;
        const allowed_types = ['image/png', 'image/jpeg'];
        const max_height = 2000;
        const max_width = 2000;
        if (fileInput.target.files[0].size > max_size) {
            this.imageError =
                'Sobrepasa el tamaño ' + max_size / 1000 + 'Mb';
            return false;
        }

        if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
            this.imageError = 'Solo se permiten imagenes ( JPG | PNG )';
            return false;
        }

        const reader = new FileReader();
        reader.onload = (e: any) => {
            const image = new Image();
            image.src = e.target.result;
            image.onload = rs => {
                const img_height = rs.currentTarget['height'];
                const img_width = rs.currentTarget['width'];
                console.log(img_height, img_width);
                if (img_height > max_height && img_width > max_width) {
                    this.imageError =
                        'El tamaño maximo es ' +
                        max_height +
                        '*' +
                        max_width +
                        'px';
                    return false;
                } else {
                    const imgBase64Path = e.target.result;
                    this.cardImageBase64 = imgBase64Path;
                    this.isImageSaved = true;
                }
            };
        };
        reader.readAsDataURL(fileInput.target.files[0]);
      }
    }

    removeImage() {
        this.cardImageBase64 = this.product_image;
        this.isImageSaved = false;
        $('input[type="file"]').val(null);
    }

    /*update product*/
    updateProduct() {
      let image : string = (this.cardImageBase64.includes('asset')) ?
        null : this.cardImageBase64;

      this.dataService.updateProduct(this.product_id,
        this.category_id,
        this.product_name,
        this.product_saleprice,
        this.product_sellerprice,
        this.product_quantity,
        this.product_treshold,
        this.product_validate_generate,
        image,
        this.product_description).subscribe(res =>{
        console.log(res);
        if (res[0] == true) {
          this.router.navigate(['/admin', 'admin-catalogue']);
          this.dataService.showMessage('Producto modificado', '', 'success');
        } else {
          this.dataService.showMessage('No se pudo modificar el producto', '', 'error');
        }
      });
    }

}
