<footer class="proviaje-footer catalogue py-3">
	<div class="px-3 text-center">
		<strong>Copyright © {{actualYear}} perfumesfindecurso.es</strong>
		Todos los derechos reservados.
		- E-mail: comercial@perfumesfindecurso.es

		<div class="text-center">
			<a 	href="assets/aviso_legal.pdf"
					target="_blank">Aviso legal</a> -
			<a 	href="assets/politica_privacidad.pdf"
					target="_blank">Terminos y condiciones</a> -
			<a 	href="assets/proteccion_datos.pdf"
					target="_blank">Proteccion de datos</a>
		</div>
	</div>
</footer>
