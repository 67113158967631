import { SimpleChanges, ViewChild } from '@angular/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Category } from 'src/app/classes/category';

@Component({
  selector: 'app-table-categorys',
  templateUrl: './table-categorys.component.html',
  styleUrls: ['./table-categorys.component.scss']
})
export class TableCategorysComponent implements OnInit {

  @Input() categoryList:Category[] = [];
  @Input() userType : string;

  @Output() deleteEvent : EventEmitter<Category> = new EventEmitter<Category>();

  displayedColumns = ['category_id', 'category_name', 'category_total_sold', 'action'];
  dataSource: MatTableDataSource<Category>;

  categoryDataSource: MatTableDataSource<Category>;

  categorys: Category[]=[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  paddingColumnsResponsive: boolean;

  constructor() {
    
    this.categoryDataSource = new MatTableDataSource(this.categoryList);
    
  }

  ngOnChanges(changes: SimpleChanges) : void {
    

    if (changes['categoryList'] != null) {
      this.categoryDataSource.data = this.categoryList;
      this.categoryDataSource.paginator = this.paginator;
      this.categoryDataSource.sort = this.sort;
    }
  }
  deleteEventTrigger(category : Category) {
    this.deleteEvent.emit(category);
  }

  ngOnInit(): void {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.paddingColumnsResponsive = true; 
    }else{
      this.paddingColumnsResponsive = false; 
    }
  }
  
}
