import { Component, OnInit } from '@angular/core';
import { DataMasterService, USER_TYPE_USER } from 'src/app/services/data-master.service';
import { Order } from '../../../classes/order.class';
import { Grade } from '../../../classes/grade.class';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-shopping-history',
  templateUrl: './shopping-history.component.html',
  styleUrls: ['./shopping-history.component.scss']
})
export class ShoppingHistoryComponent implements OnInit {
  readonly typeUser = USER_TYPE_USER;
  orderList: Order[] = [];
  grades: Grade[] = [];
  grade_id : string;

  constructor(public dataService : DataMasterService ) {
    this.dataService.setFooter("fixed");
    this.loadGradeList();
  }

  loadGradeList() {
    this.dataService.getGrade(parseInt(this.dataService.getUserId(), 10)).subscribe(response => {
      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      this.grades = response[1];
    });
  }

  ngOnInit(): void {
  }

  showRequest(grade_id : string){
    this.dataService.getOrdersGradeId(this.grade_id).subscribe(response => {

      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      if (response[1]=="no result") {
        this.dataService.showMessage('No existen compras disponibles del curso seleccionado', '', 'warning')
      } else {
        this.orderList = response[1];
        this.orderList.forEach((order, index) => {
          
          this.loadOrdersCategories(index);
          
        });
      }
    });
  }

  loadOrdersCategories(index : number){
    this.dataService.getOrderCategories(this.orderList[index].orders_id).subscribe(response => {
      if (response[1] != "no result") {
        for (let i = 0; i < response[1].length; i++) {
          response[1][i].check = false;
        }
      }
      this.orderList[index].categories = response[1];
    });
  }

}
