<mat-card class="col-lg-6 col-md-12 col-sm-12 mx-auto mt-5 fade-in">
    <mat-card-header >
      <mat-card-title >Administrar contacto</mat-card-title>
    </mat-card-header>
    <mat-card-content class="p-3">
      <form [formGroup]="contactFormGroup">
        <mat-form-field class="col-sm-12 col-md-8 col-lg-8 m-auto">
          <mat-label>Telefono</mat-label>
          <input  type="text"
                  matInput
                  formControlName="phone_number" />
        </mat-form-field>
        <mat-form-field class="col-sm-12 col-md-8 col-lg-8 m-auto">
          <mat-label>Correo</mat-label>
          <input  type="text"
                  matInput
                  formControlName="email"/>
        </mat-form-field>
        <button mat-flat-button
                color="primary" (click)="updateContact()">Guardar</button>
      </form>
    </mat-card-content>
</mat-card>