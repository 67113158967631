import { Component, OnInit } from '@angular/core';
import { DataMasterService,USER_TYPE_USER } from 'src/app/services/data-master.service';
import { Request } from '../../../classes/request.class';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-record',
  templateUrl: './record.component.html',
  styleUrls: ['./record.component.scss']
})
export class RecordComponent implements OnInit {
  record:boolean=true;
  readonly typeUser = USER_TYPE_USER;
  requestList: Request[] = [];
  constructor(public dataService : DataMasterService ) {
    this.dataService.setFooter("fixed");
  }

  ngOnInit(): void {

    this.dataService.getRequestUser(parseFloat(this.dataService.getUserId())).subscribe(response => {
      if (!response[0]) {
        this.dataService.showMessage('Error en la carga', '', 'error');
      } else if (!Array.isArray(response[1])) {
      } else {
        this.requestList = response[1].filter(item => item.request_date != null);
      }
    });
  }

}
