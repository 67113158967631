<section class="mt-2 col-12 fade-in proviaje-last-item">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        Lista de coordinadores
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="table-container">
        <app-table-moderators [userType]="dataService.getUserType()"></app-table-moderators>
      </div>
    </mat-card-content>
  </mat-card>
</section>
