<mat-card class="col-lg-6 col-md-12 col-sm-12 mx-auto mt-5 fade-in proviaje-last-item">
  <mat-card-header >
    <mat-card-title >Administrar Usuario</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form #userForm="ngForm"
          (ngSubmit)="updateSeller()">
      <mat-form-field class="d-block col-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Nombre</mat-label>
        <input  required
                matInput
                name="name"
                [(ngModel)]="seller_name">
      </mat-form-field>
      <mat-form-field class="d-block col-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Apellidos</mat-label>
        <input  required
                matInput
                name="lastname"
                [(ngModel)]="seller_lastname">
      </mat-form-field>
      <mat-form-field class="d-block col-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Correo</mat-label>
        <input  required
                type="email"
                name="email"
                matInput
                [(ngModel)]="seller_email">
      </mat-form-field>
      <mat-form-field class="d-block col-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Seleccionar curso</mat-label>
        <mat-select required
                    [(ngModel)]="grade_id"
                    name="course">
          <mat-option *ngFor="let course of courseData"
                      [value]="course.grade_id">
            {{ course.grade_name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="d-block col-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Número de telefono</mat-label>
        <input  matInput
                pattern="^[0-9]*$"
                minlength="9"
                name="phone"
                [(ngModel)]="seller_phonenumber">
      </mat-form-field>
      <mat-form-field class="d-block col-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Contraseña</mat-label>
        <input  matInput
                type="password"
                name="password"
                [(ngModel)]="seller_password">
      </mat-form-field>
      <mat-form-field class="d-block col-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Repetir contraseña</mat-label>
        <input  matInput
                type="password"
                name="password_r"
                [(ngModel)]="r_password">
      </mat-form-field>
      <button class="d-block mx-auto mt-4"
              mat-flat-button
              color="primary">Modificar</button>
    </form>
  </mat-card-content>
</mat-card>
