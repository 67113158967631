<div class="row proviaje-last-item">
  <section class="col-12 mb-5">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          Carrito
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="table-container">
          <app-table-product  [productList]="cartList"
                            [previousOrder]="request_details"
                            [previousTramited]="previousRequest?.request_date != null"
                            [disableButtons]="disableButtons"
                            tableConfig="cart"
                            [quantityList]="cartQuantityList"
                            (deleteFromCart)="deleteFromCart($event)"
                            (saveOrder)="saveOrder()"
                            (cancelOrder)="cancelOrder()"
                            (onQuantityChange)="quantityChange($event)">
          </app-table-product>
        </div>
      </mat-card-content>
    </mat-card>
  </section>
  <section class="col-12">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          Productos
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="table-container">
          <app-table-product  [productList]="productList"
                            [showImageOnHover]="true"
                            tableConfig="default"
                            (addProduct)="addProduct($event)"></app-table-product>
        </div>
      </mat-card-content>
    </mat-card>
  </section>
</div>
