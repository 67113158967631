<mat-card class="col-lg-6 col-md-12 col-sm-12 mx-auto mt-5 fade-in">
  <mat-card-header >
    <mat-card-title >Administrar contraseña</mat-card-title>
  </mat-card-header>
  <mat-card-content class="p-3">
    <form #form="ngForm"
          (ngSubmit)="verify_password(form)">
      <!-- <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Contraseña actual</mat-label>
        <input type="password" matInput [(ngModel)]="a_password"  [ngModelOptions]="{standalone: true}">
      </mat-form-field> -->
      <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Contraseña nueva</mat-label>
        <input  type="password"
                matInput
                name="password"
                [(ngModel)]="n_password"
                [type]="show_button ? 'text' : 'password'"
                required
                minlength="8" />
                <mat-icon class="icon" slot="end" style='color:#0099ff' [class]="show_eye ? 'fa fa-eye' : 'fa fa-eye-slash'" (click)="showPassword()"></mat-icon>
        <mat-error *ngIf="form.form.get('password')?.hasError('required')">
          Debe introducir una contraseña nueva
        </mat-error>
        <mat-error *ngIf="form.form.get('password')?.hasError('minlength')">
          Debe introducir una contraseña con 8 caracteres o superior.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Repetir contraseña</mat-label>
        <input  type="password"
                matInput
                name="rPassword"
                [(ngModel)]="r_password"
                [type]="show_button1 ? 'text' : 'password'"
                required
                minlength="8" />
                <mat-icon class="icon" slot="end" style='color:#0099ff' [class]="show_eye1 ? 'fa fa-eye' : 'fa fa-eye-slash'" (click)="showPassword1()"></mat-icon>
        <mat-error *ngIf="form.form.get('rPassword')?.hasError('required')">
          Debe introducir una contraseña nueva
        </mat-error>
        <mat-error *ngIf="form.form.get('rPassword')?.hasError('minlength')">
          Debe introducir una contraseña con 8 caracteres o superior.
        </mat-error>
      </mat-form-field>
      <button mat-flat-button
              color="primary">Cambiar contraseña</button>
    </form>
  </mat-card-content>
</mat-card>
