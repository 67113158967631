<!-- <button [routerLink]="['/catalogue']" class="back-button m-5" mat-icon-button><mat-icon>arrow_back</mat-icon></button> -->
<section class="pt-3 pb-3 bg-red inner-header fondo">
	<div class="text-center">
		<img src="assets/logo.png" class="col-12 col-md-6">
	</div>
</section>
<div class="text-center bg-red p-2">
	<button mat-button [routerLink]="['/catalogue']"><mat-icon class="mx-2">home</mat-icon>Inicio</button>
	<button mat-button [routerLink]="['/sales']"><mat-icon class="mx-2">attach_money</mat-icon>Promociones</button>
	<button mat-button [routerLink]="['/contact']"><mat-icon class="mx-2">local_phone</mat-icon>Contacto</button>
	<button mat-button [routerLink]="['/options']"><mat-icon class="mx-2">person</mat-icon>Iniciar sesion</button>
</div>
<form class="text-center p-2"
			(ngSubmit)="sendMail()"
			[formGroup]="contactForm">
	<mat-form-field class="d-block mx-auto my-3 col-12 col-md-6 col-lg-4">
		<mat-label>Nombre</mat-label>
		<input matInput formControlName="name">
		<mat-error *ngIf="contactForm.get('name').hasError('required')">Debe ingresar su nombre.</mat-error>
	</mat-form-field>
	<mat-form-field class="d-block mx-auto my-3 col-12 col-md-6 col-lg-4">
		<mat-label>Numero de telefono</mat-label>
		<input matInput formControlName="phone">
		<mat-error *ngIf="contactForm.get('phone').hasError('minlength') ||
		 contactForm.get('phone').hasError('pattern')">Debe ingresar un numero de telefono valido.</mat-error>
	</mat-form-field>
	<mat-form-field class="d-block mx-auto my-3 col-12 col-md-6 col-lg-4">
		<mat-label>Correo electronico</mat-label>
		<input matInput formControlName="email">
		<mat-error *ngIf="contactForm.get('email').hasError('required')">Debe ingresar su correo electronico.</mat-error>
		<mat-error *ngIf="contactForm.get('email').hasError('email')">Debe ingresar un correo electronico valido.</mat-error>
	</mat-form-field>
	<mat-form-field *ngIf="isProfessor.checked" class="d-block mx-auto my-3 col-12 col-md-6 col-lg-4">
		<mat-label>Nombre de centro</mat-label>
		<input matInput formControlName="centerName">
		<mat-error *ngIf="contactForm.get('centerName').hasError('required')">Debe ingresar el nombre de su centro.</mat-error>
	</mat-form-field>
	<mat-checkbox #isProfessor class="d-block mx-auto my-3 col-12 col-md-6 col-lg-4">Es coordinador de algun centro?</mat-checkbox>
	<mat-form-field class="d-block mx-auto my-3 col-12 col-md-9 col-lg-7">
		<mat-label>Deje su comentario</mat-label>
		<textarea matInput formControlName="message"></textarea>
		<mat-error *ngIf="contactForm.get('message').hasError('required')">Debe ingresar su mensaje.</mat-error>
	</mat-form-field>
	<button mat-flat-button color="primary">Enviar</button>
</form>
<div class="p-5 partners">
	<div class="text-center">
		<img 	src="assets/partners/p6.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p1.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p2.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p3.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p4.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p5.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
	</div>
	<div class="text-center mb-5">
		<img 	src="assets/partners/p7.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p8.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p9.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p10.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p11.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p12.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
	</div>
</div>
<footer class="proviaje-footer catalogue py-3">
	<div class="px-3 text-center">
		<strong>Copyright © {{actualYear}} perfumesfindecurso.es</strong>
		Todos los derechos reservados.
		- E-mail: comercial@perfumesfindecurso.es

		<div class="text-center">
			<a 	href="assets/aviso_legal.pdf"
					target="_blank">Aviso legal</a> -
			<a 	href="assets/politica_privacidad.pdf"
					target="_blank">Terminos y condiciones</a> -
			<a 	href="assets/proteccion_datos.pdf"
					target="_blank">Proteccion de datos</a>
		</div>
	</div>
</footer>
