import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataMasterService } from './../../../services/data-master.service'
import { Request } from 'src/app/classes/request.class';
import { Order } from 'src/app/classes/order.class';
import { Grade } from 'src/app/classes/grade.class';
import { Moderator } from 'src/app/classes/moderator.class';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-sales-detail',
  templateUrl: './sales-detail.component.html',
  styleUrls: ['./sales-detail.component.scss']
})
export class SalesDetailComponent implements OnInit {
  record : boolean = true;
  orders_id : number;
  requestListDetail : Request[] = [];
  sellerNameList : string[] = [];
  order : Order;
  grade : Grade;
  moderatorData : Moderator;
  ticketCoefficient : number = 0;
  userType : string;

  constructor(private dataService : DataMasterService,private activatedRoute: ActivatedRoute) {
    this.dataService.setFooter("fixed");
    this.userType = this.dataService.getUserType();

    this.activatedRoute.params.subscribe(params =>{
      this.orders_id = params.id;

      this.dataService.showMessage("Cargando");
      Swal.showLoading();
      this.loadOrder();
      this.loadTicketCoefficient();
    });
  }

  private loadTicketCoefficient() {
    this.dataService.getTicketCoefficient().subscribe(response => {
      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      this.ticketCoefficient = parseInt(response[1][0].ticket_coefficient, 10);
    });
  }

  private loadOrder() : void {
    this.dataService.getOrdersId(this.orders_id).subscribe(response => {
      console.log(response);

      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      this.order = response[1][0];
      this.loadGrade();
    });

    this.dataService.getOrdersDetail(this.orders_id).subscribe(response => {
      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      this.requestListDetail = response[1];
      this.requestListDetail.forEach((request, index) => {
        this.loadSeller(request, index);
      });
    });
  }

  private loadGrade() : void {
    this.dataService.getGradeId(this.order.grade_id).subscribe(response => {
      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      this.grade = response[1][0];
      this.loadModerator();
    });
  }

  private loadModerator() : void {
    this.dataService.getModerator(this.grade.moderator_id).subscribe(response => {
      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      this.moderatorData = response[1][0];
    });
  }

  private loadSeller(request : Request, index : number) : void {
    this.dataService.getSeller(request.seller_id).subscribe(response => {
      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      this.sellerNameList[index] = `${response[1][0].seller_name} ${response[1][0].seller_lastname}`;
      console.log(this.sellerNameList);

      Swal.close();
    });
  }

  ngOnInit(): void {

  }

}
