import { Component } from '@angular/core';


@Component({
  selector: 'app-tutorialmod',
  templateUrl: './tutorialmod.component.html',
  styleUrls: ['./tutorialmod.component.scss']
})
export class TutorialmodComponent  {
    pdfSource =  "../assets/aviso_legal.pdf";

    zoom: number = 1.0;
originalSize: boolean = true;

incrementZoom(amount: number) {
    this.zoom += amount;   }

  }

