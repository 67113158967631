<div class="text-center">
  <div class="text-right m-3">
    <button mat-raised-button color="primary"
      [routerLink]="['/' + userType, 'register-user',grades]">
      <mat-icon class="mr-3">person_add</mat-icon>Dar de alta</button>
  </div>
  <h2 *ngIf="sellerList.length < 1">No hay alumnos cargados</h2>
  <table mat-table *ngIf="sellerList.length > 0" [dataSource]="sellerDataSource" matSort>

    <ng-container matColumnDef="seller_name" sticky class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Nombre de Alumno </th>
      <td mat-cell *matCellDef="let seller" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{seller.seller_name}} </td>
    </ng-container>


    <ng-container matColumnDef="seller_email">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Email </th>
      <td mat-cell *matCellDef="let seller" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{seller.seller_email}} </td>
    </ng-container>

    <ng-container matColumnDef="seller_phonenumber" style="text-align:center;">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Numero de telefono </th>
      <td mat-cell *matCellDef="let seller" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ seller.seller_phonenumber != 'undefined' ? seller.seller_phonenumber : 'No facilitado' }} </td>
    </ng-container>

    <ng-container matColumnDef="action" >
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Opciones </th>
      <td mat-cell *matCellDef="let seller" class="text-center" [class.pr-1]="paddingColumnsResponsive == true" [class.pl-1]="paddingColumnsResponsive == true">
        <div class="mx-auto" [class.custom-column-px]="paddingColumnsResponsive == true">
          <button mat-raised-button color="primary" matTooltip="Modificar"
            class="mr-2" [routerLink]="['/' + userType, 'user-detail', seller.seller_id]">
            <mat-icon>edit</mat-icon>
          </button>
          <button *ngIf="seller.seller_status == 0" mat-raised-button color="primary" matTooltip="Habilitar"
              (click)="toggleStatusEventTrigger(seller)" class="mr-2">
              <mat-icon>how_to_reg</mat-icon>
            </button>
          <button *ngIf="seller.seller_status == 1" mat-raised-button color="warn" matTooltip="Deshabilitar"
              (click)="toggleStatusEventTrigger(seller)" class="mr-2">
              <mat-icon>block</mat-icon>
            </button>
          <button mat-raised-button color="warn" matTooltip="Eliminar"
            (click)="deleteEventTrigger(seller)" class="mr-2">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>

  </table>

  <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
</div>
