import { Component, OnInit } from '@angular/core';
import { DataMasterService } from 'src/app/services/data-master.service';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class SalesComponent implements OnInit {
  actualYear = new Date().getFullYear();
  constructor(public dataService : DataMasterService) { 
    this.dataService.setSideNav(false);
  }

  ngOnInit(): void {
  }

}
