<div class="text-center">
  <h2 *ngIf="request_List.length < 1">No hay pedidos cargados</h2>
  <table mat-table *ngIf="request_List.length > 0" [dataSource]="requestDataSource" matSort>

    <ng-container matColumnDef="seller_name" sticky class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Nombre de alumno </th>
      <td mat-cell *matCellDef="let request" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ request.seller_name }} {{ request.seller_lastname }} </td>
      <ng-container *ngIf="tableConfig == CART_CONFIG">
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="request_date">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Fecha de pedido </th>
      <td mat-cell *matCellDef="let request" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{request.request_modification | date}} </td>
      <ng-container *ngIf="tableConfig == CART_CONFIG">
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="request_total">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Total precio a pagar </th>
      <td mat-cell *matCellDef="let request" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ request.request_totalprofit | currency : "EUR" : "symbol" }} </td>
      <ng-container *ngIf="tableConfig == CART_CONFIG">
        <td mat-footer-cell *matFooterCellDef class="text-bold"> {{ getTotalSalePrice() | currency : "EUR" : "symbol" }} </td>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="request_totalprofit">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Total de beneficio </th>
      <td mat-cell *matCellDef="let request"  class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ (request.request_total - request.request_totalprofit) | currency : "EUR" : "symbol" }} </td>
      <ng-container *ngIf="tableConfig == CART_CONFIG">
        <td mat-footer-cell *matFooterCellDef class="text-bold"> {{ getTotalProfit() | currency : "EUR" : "symbol" }} </td>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="action" class="text-center" [class.pr-1]="paddingColumnsResponsive == true" [class.pl-1]="paddingColumnsResponsive == true">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-1]="paddingColumnsResponsive == true" [class.pl-1]="paddingColumnsResponsive == true"> Opciones </th>
      <td mat-cell *matCellDef="let request" [class.pr-1]="paddingColumnsResponsive == true" [class.pl-1]="paddingColumnsResponsive == true">
        <div class="mx-auto" [class.custom-column-px]="paddingColumnsResponsive == true">
          <button (click)="addRequestTrigger(request)" mat-raised-button
            color="primary" *ngIf="showAddButton">
            Agregar
          </button>
          <button class="ml-2" mat-raised-button color="primary"
            [routerLink]="['/mod', 'order-detail', request.request_id]"
            *ngIf="tableConfig != CART_CONFIG" >
            Detalles
          </button>
          <button (click)="deleteFromCarTrigger(request)" mat-raised-button
            color="warn" class="mr-2" *ngIf="showDeleteFromCarButton">
            <mat-icon>delete</mat-icon>
          </button>
          <button mat-raised-button
                  color="primary"
                  class="mx-2"
                  matTooltip="Editar pedido"
                  *ngIf="tableConfig != CART_CONFIG"
                  [routerLink]="['/mod', 'order', request.request_id]">
                  <mat-icon>edit</mat-icon>
          </button>
        </div>
      </td>
      <ng-container *ngIf="tableConfig == CART_CONFIG">
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>
    <ng-container *ngIf="tableConfig == CART_CONFIG">
      <tr mat-footer-row *matFooterRowDef="displayedColumns">
      </tr>
    </ng-container>
  </table>

  <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
  <button *ngIf="request_List.length > 0 && tableConfig == CART_CONFIG"
          mat-raised-button
          color="primary"
          class="mt-2 mb-2"
          [disabled]="disableButtons"
          (click)="generateOrderTrigger()" >Hacer compra
  </button>
</div>
