<mat-card class="mx-auto col-lg-4 col-md-12 col-sm-12 my-5 fade-in">
  <mat-card-header>
    <mat-card-title>Seleccionar curso</mat-card-title>
  </mat-card-header>
  <mat-card-content class="text-center p-2">
    <mat-form-field>
      <mat-label>Cursos</mat-label>
      <mat-select  [(ngModel)]="grade_id" [ngModelOptions]="{standalone: true}">
        <mat-option (click)="showRequest()" *ngFor="let course of grades" [value]="course.grade_id">{{course.grade_name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card-content>
</mat-card>
<mat-card class="fade-in proviaje-last-item" *ngIf="grade_id">
  <mat-card-header>
    <mat-card-title>Pedidos tramitados</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="table-container">
      <app-table-sales [userType]="dataService.getUserType()" [orderList]="orderList" [showGeneratePDF]="true"></app-table-sales>
    </div>
  </mat-card-content>
</mat-card>
