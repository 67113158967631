import { Component, OnInit, Input, ViewChild, SimpleChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataMasterService } from 'src/app/services/data-master.service';
import { Order } from 'src/app/classes/order.class';
import { Request } from 'src/app/classes/request.class';

@Component({
  selector: 'app-table-order',
  templateUrl: './table-order.component.html',
  styleUrls: ['./table-order.component.scss']
})
export class TableOrderComponent implements OnInit {

  @Input() showUser : boolean = false;
  @Input() showCourse : boolean = false;
  @Input() sellerNameList : string[] = [];
  @Input() showSellerProfit : boolean = false;
  @Input() userType : string;
  @Input() requestList : Request[] = [];

  displayedColumns = [
    'request_modification',
    'request_totalprofit',
    'request_total',
    'action'
  ];

  requestDataSource : MatTableDataSource<Request>;

  // @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  paddingColumnsResponsive: boolean;

  constructor() {
    this.requestDataSource = new MatTableDataSource(this.requestList);
  }

  ngOnChanges(changes: SimpleChanges) : void {
    //console.log(this.sellerNameList);


    if (changes['requestList'] != null) {
      this.requestDataSource.data = this.requestList;
    }

    if (changes['showSellerProfit'] && this.showSellerProfit && !this.displayedColumns.includes('showSellerProfit')) {
      this.displayedColumns.pop();
      this.displayedColumns = this.displayedColumns.concat(['request_total_seller_profit', 'action']);
    }

    if (changes['showUser'] && this.showUser && !this.displayedColumns.includes('user_name')) {
      this.displayedColumns = ['user_name'].concat(this.displayedColumns);
    }

    if (changes['showCourse'] && this.showCourse && !this.displayedColumns.includes('course_name')) {
      this.displayedColumns = ['course_name'].concat(this.displayedColumns);
    }

    //console.log(this.displayedColumns);
  }

  ngOnInit(): void {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.paddingColumnsResponsive = true; 
    }else{
      this.paddingColumnsResponsive = false; 
    }
    this.requestDataSource.sort = this.sort;
  }
}
