import { Component, OnInit } from '@angular/core';
import { DataMasterService } from 'src/app/services/data-master.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss']
})
export class OptionsComponent implements OnInit {
  opciones:boolean=true;
  user="user"
  actualYear = new Date().getFullYear();
  
  constructor(public dataService : DataMasterService ) {
    this.dataService.setSideNav(false);
  }

  ngOnInit(): void {
    /*if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      this.dataService.showMessage({
        title: "Descargar aplicacion",
        text: "Esta web no esta optimizada para uso desde dispositivos moviles, por favor, descargue la aplicacion movil.",
        icon: "warning",
        showCancelButton: true,
        // showDenyButton: true,
        confirmButtonColor: '#3085d6',
        denyButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Descargar Android',
        denyButtonText: 'Descargar iOS',
        cancelButtonText: 'Seguir en la web'
      }).then(result => {
        if (result.isConfirmed) {
          window
          .open(`https://play.google.com/store/apps/details?id=es.proviaje.app&gl=ES`, '_blank');
        } else if (result.isDenied) { 
          window
          .open(`https://play.google.com/store/apps/details?id=es.proviaje.app&gl=ES`, '_blank');
        }
      });
    }*/
  }

}
