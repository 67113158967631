<!--<div class="col-md-8 form-group">        
    <input type="file" class="form-control" (change)="addfile($event)" 
    placeholder="Upload file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">        
</div> -->

<mat-card class="col-lg-6 col-md-12 col-sm-12 mx-auto mt-5 fade-in">
    <mat-card-header >
      <mat-card-title> Volcado Excel</mat-card-title>
    </mat-card-header>
    <mat-card-content class="p-3 form-group">
        <div class="col-sm-12 col-md-8 col-lg-8 m-auto text-center">
          <h3 class="font-weight-bold">Ingrese archivo Excel</h3>
          <i class="fa fa-file-excel fa-7x mb-3"></i>
          <input type="file" class="form-control" (change)="addfile($event)" 
            placeholder="Upload file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
        </div>
    </mat-card-content>
</mat-card>

<mat-card class="fade-in proviaje-last-item mt-3" *ngIf="excelList.length > 0">
  <mat-card-header>
    <mat-card-title>Lista de volcado de excel</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="table-container">
      <app-table-excel [excelList]="excelList"></app-table-excel>
    </div>
  </mat-card-content>
</mat-card>
