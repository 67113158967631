import { Component, OnInit } from '@angular/core';

import { DataMasterService, USER_TYPE_ADMIN } from 'src/app/services/data-master.service';
import { Category } from '../../../classes/category';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-categorys',
  templateUrl: './admin-categorys.component.html',
  styleUrls: ['./admin-categorys.component.scss']
})
export class AdminCategorysComponent implements OnInit {
  
  categoryList: Category[] = [];
  showRegisterCategory : boolean = false;
  category_name : string;
  userType = USER_TYPE_ADMIN;

  constructor(private dataService : DataMasterService) { 
    this.dataService.setFooter("fixed");
    this.loadCategoryList();
  }

  loadCategoryList() {
    this.dataService.getAllCategorys().subscribe(response =>{
      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      if (response[1] == "no result") {
        this.categoryList = [];
        this.dataService.showMessage('No existen categorías actualmente, por favor agregue una nueva.', '', 'warning');
        
      } else {
        this.categoryList = response[1];
        console.log(this.categoryList);
      }
    });
  }

  showRegisterNewCategory(){
    if (this.showRegisterCategory == false) {
      this.showRegisterCategory = true;
    }else{
      this.category_name = null;
      this.showRegisterCategory = false;
    } 
  }

  registerCategory(){
    this.dataService.insertCategory(this.category_name).subscribe(responseInsert =>{
      console.log(responseInsert);

      if (!responseInsert[0]) {
        console.error(responseInsert[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      this.showRegisterCategory = false;
      this.category_name = null;
      this.dataService.showMessage('Se registro la categoría satisfactoriamente.', '', 'success');

      this.loadCategoryList();
    });
  }

  deleteCategoryEvent(category : Category) {

    const category_id = category.category_id;

    this.dataService.deleteCategory(category_id).subscribe(response => {
      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('No se ha podido eliminar la categoría', '', 'error');
        return;
      }

      if (response[0] == true) {
        this.dataService.showMessage('Categoría eliminada', '', 'success');
        this.loadCategoryList();
      }
      
    });
   
  }

  ngOnInit(): void {
  }

}
