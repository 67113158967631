import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { DataMasterService } from 'src/app/services/data-master.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-contact',
  templateUrl: './admin-contact.component.html',
  styleUrls: ['./admin-contact.component.scss']
})
export class AdminContactComponent implements OnInit {

  contactFormGroup : FormGroup;
  constructor( public  formBuilder : FormBuilder, public dataService : DataMasterService) { 
    this.loadContactData();
  }

  ngOnInit(): void {
    this.contactFormGroup = this.formBuilder.group({
      phone_number: [''],
      email: [''],
      id: ['']
    });
  }

  loadContactData(){
    this.dataService.getContact().subscribe(response =>{
      console.log(response);
      if (response[1].length > 0) {
        this.contactFormGroup.patchValue({
          phone_number: response[1][0].phone_number,
          email: response[1][0].email,
          id:response[1][0].id
        });
      }
    });
  }

  updateContact(){
    this.dataService.updateContact(this.contactFormGroup.value.id,this.contactFormGroup.value.phone_number,this.contactFormGroup.value.email).subscribe(response =>{
      if (response[1] == '') {
        Swal.fire({
          title: 'Contacto Actualizado',
          icon: 'success',
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        })
      }
    });
  }

}
