import { Component, OnInit } from '@angular/core';

import { DataMasterService } from 'src/app/services/data-master.service';

import { Order } from '../../../classes/order.class';
import { Grade } from '../../../classes/grade.class';
import { Moderator } from '../../../classes/moderator.class';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-purchases',
  templateUrl: './admin-purchases.component.html',
  styleUrls: ['./admin-purchases.component.scss']
})
export class AdminPurchasesComponent implements OnInit {
  
  orderModeratorList: Order[] = [];
  grades: Grade[] = [];
  userType : string;
  institutions : Moderator[] = [];
  moderators : Moderator[] = [];
  institutionName : string;

  constructor(private dataService : DataMasterService ) {
    
    this.userType = this.dataService.getUserType();
    this.loadInstitutions();
    
  }

  loadInstitutions() {
    this.dataService.getAllModerators().subscribe(response => {
      if (!response[0]) {
        //console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      this.moderators = response[1];
      this.institutions = response[1].map(item => item.moderator_institution);
      this.loadOrders();
    });
  }

  public loadOrders() : void {
    this.dataService.getAllOrders().subscribe(response => {
      Swal.close();
      if (!response[0]) {
        //console.error(response[1]);
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      if (response[1] == "no result") {
        //nada
      } else {
        console.log("orders");
        //console.log(response[1]);
        this.orderModeratorList = response[1];
        this.orderModeratorList.forEach((order, index) => {
          this.loadGradeData(index);
          this.loadOrdersCategories(index);
          this.dataService.setFooter("sticky");
        });
        console.log(this.orderModeratorList);
      }
    });
  }

  loadOrdersCategories(index : number){
    this.dataService.getOrderCategories(this.orderModeratorList[index].orders_id).subscribe(response => {
      if (response[1] != "no result") {
        for (let i = 0; i < response[1].length; i++) {
          response[1][i].check = false;
        }
      }
      this.orderModeratorList[index].categories = response[1];
    });
  }

  public loadGradeData(index : number) : void {
    this.dataService.getGradeId(this.orderModeratorList[index].grade_id).subscribe(response => {
      if (!response[0]) {
        //console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }


      if (Array.isArray(response[1]) && response[1].length > 0) {
        //console.log(response);
        this.orderModeratorList[index].orders_grade = response[1][0].grade_name;
        const moderator = this.moderators.filter(item => item.moderator_id == response[1][0].moderator_id)[0];
        this.orderModeratorList[index].orders_institution = moderator.moderator_institution;
      }

      //console.log(this.orderModeratorList);

    });
  }

  ngOnInit(): void {}

  updateReadedStatus(order : Order) {
    this.dataService.showMessage('Cargando');
    Swal.showLoading();
    this.dataService.updateOrders(order.orders_id, order.orders_readed).subscribe(response => {
      if (!response[0]) {
        this.dataService.showMessage("Error inesperado", "Ha ocurrido un error inesperado en la base de datos.", "error");
        return;
      }
      Swal.close();

      this.loadOrders();
    });
  }

  generatePDF() {
    const base_url = this.dataService.url_base;
    window
    .open(`${base_url}/services/generaloperations/generate_summary_pdf_categories.php?moderator_institution=${this.institutionName}`, '_blank');
  }

}
