import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { DataMasterService } from './../../services/data-master.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactForm : FormGroup;
  actualYear = new Date().getFullYear();

  constructor(private formBuilder : FormBuilder, private dataService : DataMasterService) { 
    this.dataService.setSideNav(false);
  }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      phone: ['', [Validators.minLength(9), Validators.pattern('^[0-9]*$')]],
      email: ['', [Validators.email, Validators.required]],
      centerName: [''],
      message: ['', [Validators.required]]
    });
  }

  sendMail() : void {
    if (this.contactForm.invalid) {
      this.dataService.showMessage('Error en el formulario', 'Tiene errores presentes en el formulario. Por favor, corrijalos antes de enviar.', 'error');
      return;
    }

    this.dataService.sendContactForm(this.contactForm.value).subscribe(response => {
      if (!response[0] || !response[1]) {
        this.dataService.showMessage("Error inesperado", "Ha ocurrrido un error inesperado durante el envio de su formulario.", 'error');
        return;
      }

      this.dataService.showMessage("Correo enviado", "El formulario ha sido enviado exitosamente.", 'success');
    });

  }
}
