<mat-card class="col-lg-8 col-md-6 mx-auto mt-5 p-3 fade-in" *ngIf="opciones">
	<mat-card-content>
		<form class="px-3 p-md-0">
		<img class="option-logo" src="assets/logo.png">
		<button [routerLink]="['/user', 'login']"
				class="d-block my-3 col-12 mx-auto d-md-inline-block m-md-3 col-md-5"
				mat-flat-button
				color="accent"><mat-icon class="mx-2">login</mat-icon>Inicio de Alumno</button>
		<button [routerLink]="['/mod', 'login']"
				class="d-block my-3 col-12 mx-auto d-md-inline-block m-md-3 col-md-5"
				mat-flat-button
				color="accent"><mat-icon class="mx-2">login</mat-icon>Inicio de Coordinador</button>
		<!-- <button  [routerLink]="['/admin', 'login']" class="d-block mx-auto mt-2" mat-flat-button color="accent">Inicio de Administrador</button> -->
		<button [routerLink]="['/register']"
				class="d-block my-3 col-12 mx-auto d-md-inline-block m-md-3 col-md-5"
				mat-flat-button
				color="accent"><mat-icon class="mx-2">person_add</mat-icon>Autoregistro del Alumno</button>
		<a href="https://www.perfumesfindecurso.es/"
				class="d-block my-3 col-12 mx-auto d-md-inline-block m-md-3 col-md-5"
				mat-flat-button
				color="accent"><mat-icon class="mx-2">shopping_cart</mat-icon>Volver a la web</a>
		</form><br><div class="centrado">
<h2>También disponible en:</h2>
<p>
<a href="https://play.google.com/store/apps/details?id=es.lesventsdegrasse.app" ><img src="./assets/playstore.png"  width="200px"></a>
<a href="https://apps.apple.com/es/app/perfumesfindecurso/id1645474050" ><img src="./assets/appstore.png"  width="200px"></a>
</p>
</div>
	</mat-card-content>
</mat-card>

<footer class="proviaje-footer-home py-2">
	<div class="px-3 text-center">
	  <strong>Copyright © {{actualYear}} perfumesfindecurso.es</strong>
	  Todos los derechos reservados.
	  - E-mail: comercial@perfumesfindecurso.es
  
	  <div class="text-center">
		<a 	href="assets/aviso_legal.pdf"
			target="_blank">Aviso legal</a> -
		<a 	href="assets/politica_privacidad.pdf"
			target="_blank">Terminos y condiciones</a> -
		<a 	href="assets/proteccion_datos.pdf"
			target="_blank">Proteccion de datos</a>
	  </div>
	</div>
  </footer>