import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DataMasterService } from 'src/app/services/data-master.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-password',
  templateUrl: './admin-password.component.html',
  styleUrls: ['./admin-password.component.scss']
})
export class AdminPasswordComponent implements OnInit {
  n_password:string;
  r_password:string;
  a_password:string;

  userType : string;

  token : string;

  constructor(private dataService : DataMasterService, private route : ActivatedRoute,
    private router : Router) {
    this.dataService.setFooter("fixed");
    console.log(this.dataService.getUserType());

    this.route.params.subscribe(params => {
      if (params.type != null) {
        this.userType = params.type;
      }

      if (params.token != null) {
        this.token = params.token;
      }
    });
  }

    // variable
    show_button: Boolean = false;
    show_eye: Boolean = false;
  
  //Function
  showPassword() {
      this.show_button = !this.show_button;
      this.show_eye = !this.show_eye;
    }
  
        // variable
        show_button1: Boolean = false;
        show_eye1: Boolean = false;
      
      //Function
      showPassword1() {
          this.show_button1 = !this.show_button1;
          this.show_eye1 = !this.show_eye1;
        }
  
  verify_password(form) {
  //validate password
  if (this.n_password != null) {
    var passwordValidate =  /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(this.n_password);
    if ( passwordValidate  == false) {
      this.dataService.showMessage('Campos invalidos', 'La contraseña ingresada debe contener al menos 1 minúscula, 1 mayúscula, 1 número, y un tamaño mínimo de 8 carcateres.', 'error');
      return;
    }
  }

    if (form.invalid) {
      this.dataService.showMessage("Error en el formulario", "El formulario no es valido", "error");
      return;
    }

    if (this.n_password.length <= 0 || this.n_password != this.r_password) {
      this.dataService.showMessage('Las contraseñas no coinciden', '', 'error');
    } else if (this.userType == "user") {

      this.dataService.updatePasswordUser(parseFloat(this.dataService.getUserId()),this.r_password)
        .subscribe(responseUser => {
        if(responseUser[0] == true){
          this.dataService.showMessage('Contraseña de usuairo cambiada', '', 'success');
        }else{
          this.dataService.showMessage('No se pudo cambiar la contraseña de usuario', '', 'error');
        }
      });


    } else if (this.userType == "mod") {

      this.dataService.updatePasswordMod(parseFloat(this.dataService.getUserId()), this.r_password)
        .subscribe(responseModerator => {
        if(responseModerator[0] == true){
          this.dataService.showMessage('Contraseña de coordinador cambiada', '', 'success');
        }else{
          this.dataService.showMessage('No se pudo cambiar la contraseña de coordinador', '', 'error');
        }
      });

    } else if (this.dataService.getUserType() == "admin") {

      this.dataService.updatePasswordAdmin(parseFloat(this.dataService.getUserId()),this.r_password)
      .subscribe(responseAdmin=>{
        if (responseAdmin[0] == true) {
          this.dataService.showMessage('Contraseña de administrador cambiada', '', 'success');
        } else {
          this.dataService.showMessage('No se pudo cambiar la contraseña de administrador', '', 'error');
        }
      });

    } else if (this.userType == "moderator") {
      const options = {
        token: this.token,
        moderator_password: this.r_password
      };

      this.dataService.recoverPassword(options).subscribe(response => {
        console.log(response);

        if (!response[0]) {
          this.dataService.showMessage('No se pudo cambiar la contraseña', '', 'error');
        }

        this.dataService.showMessage({
          title: "La contraseña ha sido cambiada con exito",
          text: "¿Que le gustaría hacer ahora?",
          icon: "success",
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonColor: '#3085d6',
          denyButtonColor: '#3085d6',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Continuar en app Android',
          denyButtonText: 'Continuar en app iOS',
          cancelButtonText: 'Continuar en version web',
        }).then(result => {
          if (result.isConfirmed) {
            window
            .open(`https://play.google.com/store/apps/details?id=es.lesventsdegrasse.app`, '_self');
          } else if (result.isDenied) { 
            window
            .open(`https://apps.apple.com/es/app/perfumesfindecurso/id1645474050`, '_self');
          } else if (result.dismiss === Swal.DismissReason.cancel) { 
            window
            .open("https://perfumesfindecurso.es/app", '_self');
          }
        });
      });
    } else if (this.userType == "seller") {
      const options = {
        token: this.token,
        seller_password: this.r_password
      };

      this.dataService.recoverPassword(options).subscribe(response => {
        console.log(response);

        if (!response[0]) {
          this.dataService.showMessage('No se pudo cambiar la contraseña', '', 'error');
        }

        this.dataService.showMessage({
          title: "La contraseña ha sido cambiada con exito",
          text: "¿Que le gustaría hacer ahora?",
          icon: "success",
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonColor: '#3085d6',
          denyButtonColor: '#3085d6',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Continuar en app Android',
          denyButtonText: 'Continuar en app iOS',
          cancelButtonText: 'Continuar en version web',
        }).then(result => {
          if (result.isConfirmed) {
            window
            .open(`https://play.google.com/store/apps/details?id=es.lesventsdegrasse.app`, '_self');
          } else if (result.isDenied) { 
            window
            .open(`https://apps.apple.com/es/app/perfumesfindecurso/id1645474050`, '_self');
          } else if (result.dismiss === Swal.DismissReason.cancel) { 
            window
            .open("https://perfumesfindecurso.es/app", '_self');
          }
        });
      });
    }
  }

  ngOnInit(): void {
  }

}
