import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { DataMasterService } from 'src/app/services/data-master.service';

import { Request } from 'src/app/classes/request.class';
import { Seller } from 'src/app/classes/seller.class';
import { Product } from 'src/app/classes/product.class';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {
  orderData : Request = null;
  sellerData : Seller = null;
  productData : Product[] = [];
  quantityData : number[] = [];
  orderId : any;
  ticketCoefficient : number = 0;

  constructor(private dataService : DataMasterService,private activatedRoute: ActivatedRoute  ) {
    this.dataService.setFooter("sticky");
    this.activatedRoute.params.subscribe(params =>{
      this.orderId = params.id;
      this.loadRequest(params.id);
      // this.loadRequestDetails(params.id);
      this.loadCategoryList();
      this.loadTicketCoefficient();
    });
  }

  private loadTicketCoefficient() {
    this.dataService.getTicketCoefficient().subscribe(response => {
      if (!response[0]) {
        //console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      this.ticketCoefficient = parseInt(response[1][0].ticket_coefficient, 10);

    });
  }

  private loadCategoryList() {
    this.dataService.getAllCategorys().subscribe(response => {
      if (!response[0]) {
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      } else if (!Array.isArray(response[1])) {
        this.dataService.showMessage('No hay productos', 'No hay productos registrados.', 'warning');
        return;
      } else {
        this.loadRequestDetails(this.orderId, response[1]);
      }
    });
  }

  private loadRequest(requestId) : void {
    this.dataService.getRequest(requestId).subscribe(response => {
      //console.log(response);

      if (!response[0] || !Array.isArray(response[1])) {
          this.dataService.showMessage('Error en la carga de detalles', '', 'error');
      } else {
        this.orderData = response[1][0];

        this.loadSellerData(this.orderData.seller_id);
      }

    });
  }

  private loadRequestDetails(requestId, categories) : void {
    this.dataService.getRequestDetails(requestId).subscribe(response => {
      //console.log(response);

      if (!response[0]) {
        this.dataService.showMessage('Error en la carga de detalles', '', 'error');
      } else if (!Array.isArray(response[1])) {
      } else {

        
        categories.forEach(category => {
          const filtered = response[1]
            .filter(item => category.category_id == item.category_id)
            .map(item => {
              delete item.category_name;
              item.product_sellerprice = item.requestdetails_sellerprice,
              item.product_quantity = item.requestdetails_quantity,
              item.product_saleprice = item.requestdetails_saleprice
              return item;
          });

          if (filtered.length > 0) { 
            this.productData = this.productData.concat([category]);
            this.productData = this.productData.concat(filtered);
          }
        });

        // this.productData = response[1].map(item => {
        //   return {
        //     ...item,
        //     product_sellerprice: item.requestdetails_sellerprice,
        //     product_quantity: item.requestdetails_quantity,
        //     product_saleprice: item.requestdetails_saleprice
        //   }
        // });

        this.quantityData = this.productData.map(item => item.product_quantity);
      }
    });
  }

  private loadSellerData(sellerId) : void {
    this.dataService.getSeller(sellerId).subscribe(response => {
      //console.log(response);

      if (!response[0] || !Array.isArray(response[1])) {
        this.dataService.showMessage('Error en la carga de información del cliente', '', 'error');
      } else {
        this.sellerData = response[1][0];
      }
    });
  }
  ngOnInit(): void {
  }

}
