import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { DataMasterService, CategoryGroup } from 'src/app/services/data-master.service';

import { Grade } from './../../classes/grade.class';
import { Seller } from './../../classes/seller.class';
import { Moderator } from './../../classes/moderator.class';
import { Product } from './../../classes/product.class';
import { Request } from './../../classes/request.class';
import { Requestdetails } from './../../classes/requestdetails.class';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  private userType : string;

  disableButtons : boolean = false;

  deletedList : Product[] = [];
  cartList : Product[] = [];
  productList : (Product | CategoryGroup)[] = [];
  insertList : Product[] = [];
  cartQuantityList : number[] = [];
  request_details : Requestdetails[] = [];
  previousRequest : Request;

  loadedMessage : string = ''

  sellerData : Seller;
  gradeData : Grade;
  modData : Moderator;

  adminMode : boolean = false;
  amdinRequestId : number;

  loadingLoad : number = 0;
  swalMessages : any = [];
  swalShowed : boolean = false;
  messageAdded : EventEmitter<void>;
  messageClosed : EventEmitter<void>;
  loadReduced : EventEmitter<string>;
  loadIncreased : EventEmitter<void>;

  constructor(private dataService : DataMasterService, private route : ActivatedRoute,
    private router : Router, private location : Location) {

    this.messageClosed = new EventEmitter<void>();
    this.messageClosed.subscribe(response => {
      this.showNextSwalMessage();
    });

    this.messageAdded = new EventEmitter<void>();
    this.messageAdded.subscribe(response => {
      this.showNextSwalMessage();
    });

    this.loadReduced = new EventEmitter<string>();
    this.loadReduced.subscribe(response => {

      if (this.loadingLoad == 0) {
        Swal.close();
        this.dataService.setFooter("sticky");
        this.messageClosed.emit();
      }
    });

    this.loadIncreased = new EventEmitter<void>();
    this.loadIncreased.subscribe(response => {
      if (this.loadingLoad == 0) {
        this.dataService.showMessage("Cargando");
        Swal.showLoading();
      }
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.userType = params.type;
      if (params.type == 'user') {
        const userId = parseInt(this.dataService.getUserId(), 10);
        this.loadSellerData(userId);
      } else {
        this.adminMode = true;
        this.amdinRequestId = parseInt(params.id, 10);
        this.loadRequestAdmin(() => {
          const sellerId = this.previousRequest.seller_id;
          this.loadSellerData(sellerId);
        });
      }
    });
  }

  private showNextSwalMessage() {
    if (this.loadingLoad == 0 && !this.swalShowed) {
      const swalMessage = this.swalMessages.shift();
      console.log(swalMessage);
      if (swalMessage != null) {
        this.swalShowed = true;
        this.showSwalMessage(swalMessage);
      } else {
        this.swalShowed = false;
      }
    }
  }

  private showSwalMessage(swalMessage : any) {
    this.dataService.showMessage(swalMessage[0], swalMessage[1], swalMessage[2]).then(result => {
      this.swalShowed = false;
      this.messageClosed.emit();
    });
  }

  private addSwalMessage(swalMessage : string[]) : void {
    this.swalMessages.push(swalMessage);
    this.messageAdded.emit();
  }

  private addLoad() : void {
    this.loadIncreased.emit();
    this.loadingLoad++;
  }

  private substractLoad(reason : string) : void {
    this.loadingLoad--;
    this.loadingLoad = Math.max(this.loadingLoad, 0);
    this.loadReduced.emit(reason);
    console.log(reason, this.loadingLoad);
  }

  loadRequestAdmin(callback : () => void = () => null) : void {
    this.addLoad();
    this.dataService.getRequest(this.amdinRequestId).subscribe(response => {
      if (!response[0]) {
        this.substractLoad("loadRequestAdmin - error");
        this.addSwalMessage(["Error inesperado", "Ha ocurrido un error inesperado en la base de datos.", "error"])
        return;
      } else if (!Array.isArray(response[1]) || response[1].length < 1) {
        this.substractLoad("loadRequestAdmin - error");
        //this.addSwalMessage(["Error inesperado", "Error en la carga de datos del usuario.", "error"]);
        return;
      }
      this.previousRequest = response[1][0];
      callback();
      this.substractLoad("loadRequestAdmin - success");
    });
  }

  // PLS NO HACER ESTO EN CASA :V
  private loadSellerData(sellerId : number) {
    this.addLoad();
    this.dataService.getSeller(sellerId).subscribe(response => {
      console.log(response);

      if (!response[0]) {
        this.substractLoad("loadSellerData - error");
        this.addSwalMessage(["Error inesperado", "Ha ocurrido un error inesperado en la base de datos.", "error"]);
        return;
      } else if (!Array.isArray(response[1]) || response[1].length < 1) {
        this.substractLoad("loadSellerData - error");
        this.addSwalMessage(["Error inesperado", "Error en la carga de datos del usuario.", "error"]);
        return;
      }

      this.sellerData = response[1][0];
      this.loadGradeData();
      this.substractLoad("loadSellerData - success");
    });
  }

  private loadGradeData() {
    this.addLoad();
    this.dataService.getGradeId(this.sellerData.grade_id.toString()).subscribe(response => {
      console.log(response);

      if (!response[0]) {
        this.substractLoad("loadGradeData - error");
        this.addSwalMessage(["Error inesperado", "Ha ocurrido un error inesperado en la base de datos.", "error"]);
        return;
      } else if (!Array.isArray(response[1]) || response[1].length < 1) {
        this.substractLoad("loadGradeData - error");
        this.addSwalMessage(["Error inesperado", "Error en la carga de datos del curso.", "error"]);
        return;
      }

      this.gradeData = response[1][0];
      this.loadModData();
      this.substractLoad("loadGradeData - success");
    });
  }

  private loadModData() {
    this.addLoad();
    this.dataService.getModerator(this.gradeData.moderator_id).subscribe(response => {
      if (!response[0]) {
        this.substractLoad("loadModData - error");
        this.addSwalMessage(["Error inesperado", "Ha ocurrido un error inesperado en la base de datos.", "error"]);
        return;
      } else if (!Array.isArray(response[1]) || response[1].length < 1) {
        this.substractLoad("loadModData - error");
        this.addSwalMessage(["Error inesperado", "Error en la carga de datos del coordinador.", "error"]);
        return;
      }

      this.modData = response[1][0];
      this.loadCategoryList();
      this.substractLoad("loadModData - success");
    });
  }

  private loadCategoryList() {
    this.addLoad();

    this.dataService.getAllCategorys().subscribe(response => {
      if (!response[0]) {
        this.substractLoad("loadCategoryList - error");
        this.addSwalMessage(['Error inesperado', 'Error inesperado en la base de datos.', 'error']);
        return;
      } else if (!Array.isArray(response[1])) {
        this.substractLoad("loadCategoryList - error");
        this.addSwalMessage(['No hay productos', 'No hay productos registrados.', 'warning']);
        return;
      } else {
        this.loadProductList(response[1]);
        this.substractLoad("loadLastRequest - success");
      }
    });
  }

  private loadProductList(categories) {
    this.addLoad();

    this.request_details = null;
    this.previousRequest = null;

    this.cartList = [];
    this.cartQuantityList = [];

    this.deletedList = [];
    this.insertList = [];

    this.dataService.getAllProducts().subscribe(response => {
      console.log(response);
      if (!response[0]) {
        this.substractLoad("loadProductList - error");
        this.addSwalMessage(['Error inesperado', 'Error inesperado en la base de datos.', 'error']);
        return;
      } else if (!Array.isArray(response[1])) {
        this.substractLoad("loadProductList - error");
        this.addSwalMessage(['No hay productos', 'No hay productos registrados.', 'warning']);
        return;
      } else {
        this.productList = [];

        categories.forEach(category => {
          this.productList = this.productList.concat([category]);
          this.productList = this.productList.concat(response[1]
            .filter(item => category.category_id == item.category_id)
            .map(item => {
              item.product_saleprice = +item.product_saleprice,
              item.product_sellerprice = +item.product_sellerprice + +this.modData.moderator_overprice
            return item;
          }));
        });

        if (!this.adminMode) {
          this.loadLastRequest();
        } else if (this.adminMode) {
          this.loadRequestAdmin(() => {
            this.loadRequestDetails();
          });
        }

        this.substractLoad("loadProductList - success");
      }
    });
  }

  private loadLastRequest() {
    this.addLoad();
    this.dataService.getLastRequest(parseInt(this.dataService.getUserId(), 10)).subscribe(response => {
      console.log(response);

      if (!response[0]) {
        console.error(response);
        this.substractLoad("loadLastRequest - error");
        this.addSwalMessage(['Error inesperado', 'Error inesperado en la base de datos.', 'error']);
        return;
      } else if (!Array.isArray(response[1])) {
        this.substractLoad("loadLastRequest - empty");
        this.cartList = [];
        this.request_details = null;
        this.cartQuantityList = [];
        this.previousRequest = null;
      } else {
        if (response[1].length > 0) {
          this.previousRequest = response[1][0];
          this.loadRequestDetails();
          this.substractLoad("loadLastRequest - success");
        }
      }
    });
  }

  private loadRequestDetails() {
    this.addLoad();
    this.dataService.getRequestDetails(this.previousRequest.request_id).subscribe(response => {
      if (!response[0]) {
      } else {
        if (response[1].length < 1) {
          this.substractLoad("loadRequestDetails - error");
          this.addSwalMessage(['No se pudieron cargar detalles de pedidos', '', 'error']);
        } else {
          this.substractLoad("loadRequestDetails - success");
          if (!this.adminMode) {
            this.addSwalMessage(['Tu pedido está pendiente de que el coordinador lo valide', '', 'info']);
          }
          this.request_details = response[1];

          if (this.cartList.length == 0) {
            const request_product_ids = this.request_details.map(item => item.product_id);
            this.cartQuantityList = this.request_details.map(item => item.requestdetails_quantity);
            const productItems : Product[] = this.productList.filter(item => !('category_name' in item)).map(item => item as Product);
            this.cartList = productItems.filter(item => request_product_ids.includes(item.product_id));
          }
        }
      }
    });
  }

  addProduct(product : Product) {
    if (!this.cartList.includes(product)) {
      this.dataService.showMessage('El producto ha sido agregado al carrito.', '', 'info');
      this.cartList = this.cartList.concat([product]);
      this.deletedList = this.deletedList.filter(item => item != product);
      if (this.previousRequest != null && this.request_details.filter(item => item.product_id == product.product_id).length < 1) {
        this.insertList.push(product);
      }
    } else {
      this.dataService.showMessage('El producto ya se encuentra en el carrito. \r\n Si lo deseas, aumenta la cantidad de este articulo en el carrito', '', 'info');
    }
  }

  deleteFromCart(product : Product) {
    // this.productList = this.productList.concat([product]);
    this.cartList = this.cartList.filter(item => item != product);
    this.deletedList.push(product);
    this.insertList = this.insertList.filter(item => item != product);
  }

  saveOrder() {
    this.disableButtons = true;
    this.addLoad();

    let details = this.cartList.map(item => {
      return {
        request_id: this.previousRequest != null ? this.previousRequest.request_id : null,
        product_id: item.product_id,
        requestdetails_quantity: 0,
        requestdetails_sellerprice: item.product_sellerprice,
        requestdetails_saleprice: item.product_saleprice
      };
    });

    for (let index = 0; index < this.cartList.length; index++) {
      if (this.previousRequest == null) {
        if (this.cartList[index].product_quantity < this.cartQuantityList[index]) {
          this.substractLoad("saveOrder - error");
          this.addSwalMessage(['No se posee la cantidad de productos solicitada', '', 'error']);
          console.error("Cantidades invalidas",
          this.cartList[index].product_quantity,
          this.cartQuantityList[index]);
          return;
        }
      } else {
        console.log(this.previousRequest);
        const product = this.cartList[index];
        let requestdetail : any = this.request_details.filter(item => item.product_id == product.product_id);
        if (requestdetail.length > 0) {
          requestdetail = requestdetail[0];
          if (+this.cartList[index].product_quantity + +requestdetail.requestdetails_quantity < this.cartQuantityList[index]) {
            this.substractLoad("saveOrder - error");
            this.addSwalMessage(['No se posee la cantidad de productos solicitada', '', 'error']);
            console.error("Cantidades invalidas",
            this.cartList[index].product_quantity,
            requestdetail.requestdetails_quantity,
            this.cartQuantityList[index]);
            return;
          }
        }
      }
      details[index].requestdetails_quantity = this.cartQuantityList[index];
    }

    this.request_details = details;
    console.log(this.request_details);

    const request_details_string = JSON.stringify(this.request_details);

    let product_total = 0;
    for (let i in this.cartList) {
      product_total = product_total + this.cartList[i].product_saleprice;
    }

    let fecha : Date = new Date();
    let request_modification : string;
    let y = `${fecha.getFullYear()}`;
    let m = `${fecha.getMonth() + 1}`;
    let d = `${fecha.getDay()}`;
    request_modification = `${y}-${(m.length < 2) ? '0' + m : m}-${(d.length < 2) ? '0' + d : d}`;

    if (this.previousRequest != null) {
      this.dataService.updateRequest(this.previousRequest.request_id,
        request_modification, request_details_string).subscribe(response => {

        if (!response[0]) {
          this.substractLoad("saveOrder - error");
          this.addSwalMessage(['No se pudo modificar el pedido', '', 'error']);
          return;
        }

        console.log(this.insertList);
        let insertIndex = -1;
        let deleteIndex = -1;
        this.insertList.forEach((product, index) => {
          this.addLoad();
          const detail = this.request_details.filter(item => item.product_id == product.product_id)[0];
          this.dataService.insertRequestdetails(detail.requestdetails_quantity,
            detail.requestdetails_sellerprice, detail.requestdetails_saleprice,
            detail.product_id, detail.request_id).subscribe(response => {
              this.substractLoad("insertList");
              if (!response[0]) {
                this.disableButtons = false;
                console.error(response[1]);
                this.substractLoad("saveOrder - insertlist - success");
                this.addSwalMessage(['Error inesperado', 'Error inesperado en la base de datos.', 'error']);
                return;
              };

              insertIndex++;
              if (insertIndex == this.insertList.length - 1 &&
                deleteIndex == this.deletedList.length - 1) {

                this.disableButtons = false;
                insertIndex = -1;
                this.addSwalMessage(['Tu pedido se ha modificado correctamente.', '', 'success']);
                this.loadCategoryList();
                this.substractLoad("saveOrder - success");

                if (this.adminMode) {
                  this.location.back();
                }
              }
          });
        });

        console.log(this.deletedList);
        this.deletedList.forEach((product, index) => {
          this.addLoad();
          this.dataService.deleteRequestDetails(this.previousRequest.request_id, product.product_id)
            .subscribe(response => {
              this.substractLoad("deletedList");
              if (!response[0]) {
                this.disableButtons = false;
                console.error(response[1]);
                this.substractLoad("saveOrder - deletelist - error");
                this.addSwalMessage(['Error inesperado', 'Error inesperado en la base de datos.', 'error']);
                return;
              };

              deleteIndex++;
              if (deleteIndex == this.deletedList.length - 1 &&
                insertIndex == this.insertList.length - 1) {

                this.disableButtons = false;
                deleteIndex = -1;
                this.addSwalMessage(['Tu pedido se ha modificado correctamente.', '', 'success']);
                this.loadCategoryList();
                this.substractLoad("saveOrder - success");

                if (this.adminMode) {
                  this.location.back();
                }
              }
            });
        });

        if (this.deletedList.length < 1 && this.insertList.length < 1) {
          this.disableButtons = false;
          this.substractLoad("saveOrder - success");
          this.loadCategoryList();
          this.addSwalMessage(['Tu pedido se ha modificado correctamente.', '', 'success']);

          if (this.adminMode) {
            this.location.back();
          }
        }
      });
    } else {
      this.dataService.insertRequest(request_modification, request_details_string,
        parseFloat(this.dataService.getUserId())).subscribe(respuesta => {

        console.log(respuesta);

        this.disableButtons = false;
        this.loadCategoryList();
        this.substractLoad("saveOrder - insertRequest - success");
        this.addSwalMessage(['Tu pedido se ha guardado correctamente. Podrás modificarlo tantas veces como necesites antes de que el coordinador lo valide', '', 'success']);

        if (this.adminMode) {
          this.location.back();
        }
      });
    }
  }
  cancelOrder() {
    this.addLoad();
    this.dataService.deleteRequest(this.previousRequest.request_id).subscribe(responseDelete => {
      console.log(responseDelete);
      this.substractLoad("cancelOrder - success");
      if (responseDelete[0] == true) {
        this.loadCategoryList();
        this.addSwalMessage(['Pedido cancelado', '', 'success']);
      } else {
        this.addSwalMessage(['Error al cancelar el pedido', '', 'error']);
      }
      if (this.adminMode) {
        this.location.back();
      }
    });
  }

  quantityChange(quantityList : number[]) {
    this.cartQuantityList = quantityList;
  }
}
