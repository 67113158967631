<div class="text-center">
    
    <h2 *ngIf="categoryList.length < 1">No hay categorías cargadas</h2>
    <table mat-table *ngIf="categoryList.length > 0" [dataSource]="categoryDataSource" matSort>

        <ng-container matColumnDef="category_id" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true" sticky>
            <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> ID </th>
            <td mat-cell *matCellDef="let category"  class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{category.category_id}} </td>
          </ng-container>
    
        <ng-container matColumnDef="category_name">
            <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Nombre de la categoría </th>
            <td mat-cell *matCellDef="let category" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{category.category_name}} </td>
        </ng-container>

        <ng-container matColumnDef="category_total_sold">
            <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Total vendido </th>
            <td mat-cell *matCellDef="let category" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{category.category_total_sold}} </td>
        </ng-container>

  
        <ng-container matColumnDef="action" >
            <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Opciones </th>
            <td mat-cell *matCellDef="let category" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true">
                <div class="mx-auto" [class.custom-column-px]="paddingColumnsResponsive == true">
                    <button mat-raised-button color="primary" matTooltip="Modificar"
                    class="mr-2" [routerLink]="['/' + userType, 'edit-category', category.category_id]">
                    <mat-icon>edit</mat-icon></button>
                    <button mat-raised-button color="warn" matTooltip="Eliminar"
                    (click)="deleteEventTrigger(category)" class="mr-2">
                    <mat-icon>delete</mat-icon></button>
                </div>
            </td>
        </ng-container>
  
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
    </table>
    
  
</div>
  
