<mat-card class="mb-5 col-lg-4 col-md-12 col-sm-12 mx-auto fade-in">
  <mat-card-header>
    <mat-card-title>Generar PDF Resumen</mat-card-title>
  </mat-card-header>
  <mat-card-content class="text-center p-5">
    <mat-form-field class="d-block">
      <mat-label>Nombre de centro</mat-label>
      <mat-select [(ngModel)]="institutionName">
        <mat-option *ngFor="let name of institutions" [value]="name">
          {{ name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button (click)="generatePDF()" mat-flat-button color="primary">Generar</button>
  </mat-card-content>
</mat-card>
<mat-card class="mt-5 proviaje-last-item fade-in">
  <mat-card-header>
    <mat-card-title>
      Compras realizadas
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="table-container">
      <app-table-sales  (readedEvent)="updateReadedStatus($event)"
                      [showInstitute]="true"
                      [showGrade]="true"
                      [showMark]="true"
                      [userType]="userType"
                      [orderList]="orderModeratorList"
                      [showGeneratePDF]="true"></app-table-sales>
    </div>
  </mat-card-content>
</mat-card>
