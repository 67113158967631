import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/classes/product.class';
import { DataMasterService } from 'src/app/services/data-master.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.scss']
})
export class CatalogueComponent implements OnInit {

  products : Product[] = [];
  actualYear = new Date().getFullYear();

  constructor(private dataService : DataMasterService ) {
    this.dataService.setSideNav(false);
    this.dataService.getAllProducts().subscribe(respuesta => {
      this.products = respuesta[1];
    });

  }

  ngOnInit(): void {
    this.dataService.showMessage({
      title: "Contraseña Cambiada con Exito",
      text: "¿Que le gustaría hacer ahora?",
      icon: "success",
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonColor: '#3085d6',
      denyButtonColor: '#3085d6',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Continuar en app Android',
      denyButtonText: 'Continuar en app iOS',
      cancelButtonText: 'Continuar en version web',
    }).then(result => {
      if (result.isConfirmed) {
        window
        .open(`https://play.google.com/store/apps/details?id=es.lesventsdegrasse.app`, '_self');
      } else if (result.isDenied) { 
        window
        .open(`https://apps.apple.com/es/app/perfumesfindecurso/id1645474050`, '_self');
      } else if (result.dismiss === Swal.DismissReason.cancel) { 
        window
        .open("https://perfumesfindecurso.es/app", '_self');
      }
    });
  
  }
}
