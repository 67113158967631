
<mat-card>
  <mat-card-header>
    <mat-card-title>
<h1>Manual de instrucciones</h1> </mat-card-title>
  </mat-card-header>
  </mat-card>
 <mat-card>
<mat-card-content class="lateral">
    <p align="center" style="text-align: center;"><span style="text-decoration: underline;"><span size="3" style="font-size: medium;"><b>Perfumesfindecurso</b></span></span></p>
    <p align="center" style="text-align: center;"><span style="text-decoration: underline;"><span size="3" style="font-size: medium;">Manual del usuario del alumno</span></span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">NOTA IMPORTANTE SOBRE LA VERSIÓN DE LA APLICACIÓN:</span></p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Está disponible una aplicación de escritorio, que funciona a través del navegador, y también disponemos apps para móvil, tanto para Android como para IOS.</span></p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">En el caso de querer utilizar el dispositivo móvil, recomendamos encarecidamente que se utilice la versión de móvil pertinente, ya sea Android o IOS. </span></p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;"><span style="text-decoration: underline;">Desaconsejamos el uso de la versión escritorio desde un dispositivo móvil</span></span><span size="3" style="font-size: medium;"> ya que, aun y habiéndose previsto su uso, las condiciones de accesibilidad son menos prácticas que accediendo desde un ordenador personal.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Para empezar nos dirigiremos a esta URL:</span></p>
    <p style="text-align: justify;"><span color="#0563c1" style="color: #0563c1;"><span style="text-decoration: underline;"><span size="3" style="font-size: medium;">https://www.perfumesfindecurso.es/app/options</span></span></span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <ol style="text-align: justify;">
    <li>
    <p><span size="3" style="font-size: medium;">Registro del alumno</span></p>
    </li>
    </ol>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Lo primero que debemos hacer para empezar es registrarnos en la aplicación, con lo cual obtendremos las credenciales (usuario y contraseña) para poder acceder al sistema y crear y modificar nuestros pedidos.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Para poder registrarnos, el coordinador o tutor, nos debe facilitar una clave única.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Cuando accedemos al registro del alumno, lo primero que nos pedirá será esta clave.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Si la clave es correcta, entonces nos permitirá introducir todos los datos necesarios para el registro.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Es importante rellenar bien el formulario de registro. </span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Lo primero será escoger el curso de la lista desplegable, que será el curso que el coordinador haya creado previamente.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Los requisitos para la contraseña son que debe contener como mínimo 1 letra mayúscula, 1 letra minúscula y 1 numero, y la longitud mínima es de 8 dígitos.</span></p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Si se desea se pueden utilizar símbolos.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Una vez aceptados los términos y condiciones, podemos hacer clic en registrar y si hemos introducido los datos de forma correcta, se creará el usuario y acto seguido se enviara un email al alumno confirmándole sus credenciales.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">A partir de aquí ya podemos acceder al panel de control del alumno, a través del menú de principal de la aplicación, ya sea de escritorio o de móvil.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <ol start="2" style="text-align: justify;">
    <li>
    <p><span size="3" style="font-size: medium;">Login y acceso al sistema</span></p>
    </li>
    </ol>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Para acceder al sistema como alumno, deberemos hacer clic en la opción “Inicio alumno” del menú principal. Tras introducir nuestras credenciales accederemos a la pantalla de inicio, donde se nos muestra información general acerca de las ventas y beneficios obtenidos por nuestros pedidos ya cerrados.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><a name="_GoBack"></a> <span size="3" style="font-size: medium;">Tanto para realizar un nuevo pedido, como para modificar el pedido que tengamos en curso, accederemos a la opción “Realizar pedido” del menú.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <ol start="3" style="text-align: justify;">
    <li>
    <p><span size="3" style="font-size: medium;">Realizar pedido</span></p>
    </li>
    </ol>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">En el caso de disponer ya productos en la cesta, el sistema siempre nos muestra un mensaje informativo de que hay un pedido pendiente de validación por parte del profesor. </span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Este mensaje es habitual y se mostrara a menudo para recordarnos que nuestro pedido sigue abierto para seguir incrementándolo y modificándolo, pues aún no ha sido validado/cerrado por el profesor.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Podréis añadir productos, quitarlos o modificar sus cantidades. Recordad siempre guardar los cambios mediante el botón “Guardar cambios”.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">El funcionamiento habitual, es que el coordinador establecerá una fecha límite para que confeccionéis vuestro pedido. Llegada esa fecha el validara todos los pedidos y estos ya no podrán modificarse.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Cuando el coordinador haya validado vuestro pedido, este ya se encontrará en proceso de preparación y envío junto con otros pedidos de otros alumnos, y veréis que vuestra cesta aparece vacía de nuevo.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">El coordinador os avisará llegado el momento para acordar la entrega de vuestros productos.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Previo acuerdo con el coordinador, también podréis empezar a confeccionar un nuevo pedido.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <ol start="4" style="text-align: justify;">
    <li>
    <p><span size="3" style="font-size: medium;">Historial</span></p>
    </li>
    </ol>
    <p>&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">En este apartado podemos consultar los últimos pedidos que han sido validados y cursados por el coordinador.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <ol start="5" style="text-align: justify;">
    <li>
    <p><span size="3" style="font-size: medium;">Cambio de contraseña</span></p>
    </li>
    </ol>
    <p>&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Como su nombre indica, esta opción nos permite cambiar nuestra contraseña.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <ol start="6" style="text-align: justify;">
    <li>
    <p><span size="3" style="font-size: medium;">Cerrar sesión</span></p>
    </li>
    </ol>
    <p>&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Podemos cerrar la sesión mediante este botón. Transcurridos 60 minutos de inactividad, la sesión también se cerrará automáticamente.</span></p>
    <p style="text-align: justify;">&nbsp;</p>
    <p style="text-align: justify;"><span size="3" style="font-size: medium;">Para cualquier incidencia o consulta con respecto a la aplicación, podéis dirigiros a </span><span color="#0563c1" style="color: #0563c1;"><span style="text-decoration: underline;"><a href="mailto:pedidosweb@perfumesfindecurso.es"><span size="3" style="font-size: medium;">pedidosweb@perfumesfindecurso.es</span></a></span></span><span size="3" style="font-size: medium;">. Se intentará solucionar vuestro problema o consulta con la mayor rapidez.</span></p>
              
  </mat-card-content>
</mat-card>
