<div class="row fade-in proviaje-last-item">
  <section class="col-lg-6 col-md-12 col-sm-12 mt-2">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          Detalles de pedidos
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p class="m-3 float-right"><strong>{{ sellerData != null ?
          sellerData.seller_name + " " + sellerData.seller_lastname : '' }}</strong></p>
        <p class="m-3 d-block">Estudiante que lo realiza</p>
        <mat-divider class="position-relative"></mat-divider>
        <p class="m-3 float-right"><strong>{{ orderData != null && orderData.request_date != null ?
          (orderData.request_date | date) : 'Pedido no tramitado' }}</strong></p>
        <p class="m-3 d-block">Fecha de compra</p>
        <mat-divider class="position-relative"></mat-divider>
        <p class="m-3 float-right"><strong>{{ orderData != null ?
          (orderData.request_modification | date) : '' }}</strong></p>
        <p class="m-3 d-block">Ultima modificacion del pedido</p>
        <mat-divider class="position-relative"></mat-divider>
        <p class="m-3 float-right"><strong>{{ orderData != null ?
          (orderData.request_totalprofit | currency : "EUR" : "symbol") : '' }}</strong></p>
        <p class="m-3 d-block">Total precio costo</p>
        <mat-divider class="position-relative"></mat-divider>
        <p class="m-3 float-right"><strong>{{ orderData != null ?
          (orderData.request_total | currency : "EUR" : "symbol") : '' }}</strong></p>
        <p class="m-3 d-block">Total precio venta</p>
        <mat-divider class="position-relative"></mat-divider>
        <p class="m-3 float-right"><strong>{{ orderData != null ?
          (orderData.request_total - orderData.request_totalprofit | currency : "EUR" : "symbol") : '' }}</strong></p>
        <p class="m-3 d-block">Total beneficio</p>
      </mat-card-content>
    </mat-card>
  </section>

  <section class="col-12 mt-2 mb-3">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Productos pedidos</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="table-container">
          <app-table-product  [quantityList]="quantityData" 
                            [productList]="productData" 
                            tableConfig="order_detail"></app-table-product>
        </div>
      </mat-card-content>
    </mat-card>
    </section>
    <section class="col-12 mt-2 mb-3 button">
           <button 
             onclick="history.back()"
             mat-raised-button
             color="primary"
             class="m-2 centrado">Volver atrás</button>
             
   </section> 
   <div>
