<div class="row fade-in proviaje-last-item">
  <section class="col-lg-6 col-md-12 col-sm-12 mt-2">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          Detalles de la compra
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p class="m-3 float-right"><strong>{{ moderatorData != null ? moderatorData.moderator_institution : '' }}</strong></p>
        <p class="m-3 d-block">Nombre de centro</p>
        <mat-divider class="position-relative"></mat-divider>
        <p class="m-3 float-right"><strong>{{ grade != null ? grade.grade_name : '' }}</strong></p>
        <p class="m-3 d-block">Curso solicitante</p>
        <mat-divider class="position-relative"></mat-divider>
        <p class="m-3 float-right"><strong>{{ order != null ? (order.orders_date | date) : '' }}</strong></p>
        <p class="m-3 d-block">Fecha de compra</p>
        <mat-divider class="position-relative"></mat-divider>
        <p class="m-3 float-right"><strong>{{ order != null ? (order.orders_totalprofit | currency : "EUR" : "symbol") : '' }}</strong></p>
        <p class="m-3 d-block">Total precio costo</p>
        <mat-divider class="position-relative"></mat-divider>
        <p class="m-3 float-right"><strong>{{ order != null ? (order.orders_total | currency : "EUR" : "symbol") : '' }}</strong></p>
        <p class="m-3 d-block">Total precio venta</p>
        <mat-divider class="position-relative"></mat-divider>
        <p class="m-3 float-right"><strong>{{ order != null ? (order.orders_total - order.orders_totalprofit | currency : "EUR" : "symbol") : '' }}</strong></p>
        <p class="m-3 d-block">Total beneficio</p>
        <mat-divider class="position-relative"></mat-divider>
      </mat-card-content>
    </mat-card>
  </section>
  <section class="col-12 mt-2 mb-3">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          Pedidos de alumnos
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="table-container">
          <app-table-order  [sellerNameList]="sellerNameList"
                          [showUser]="true"
                          [userType]="userType"
                          [requestList]="requestListDetail"
                          [showSellerProfit]="true"></app-table-order>
        </div>
      </mat-card-content>
    </mat-card>
  </section>
  <section class="col-12 mt-2 mb-3 button">
    <button 
      onclick="history.back()"
      mat-raised-button
      color="primary"
      class="m-2 centrado">Volver atrás</button>
      
</section>
<div>
