<mat-card class="col-lg-6 col-md-12 col-sm-12 mx-auto fade-in mb-5">
  <mat-card-header>
    <mat-card-title>Datos del coordinador</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form #registerForm="ngForm" (ngSubmit)="regModerator()">
      <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Nombre</mat-label>
        <input  type="text"
                matInput
                name="name"
                required
                [(ngModel)]="moderator_name">
      </mat-form-field>
      <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Apellidos</mat-label>
        <input  type="text"
                matInput
                name="lastname"
                required
                [(ngModel)]="moderator_lastname">
      </mat-form-field>
      <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Nombre de centro</mat-label>
        <input  type="text"
                matInput
                name="center"
                required
                [(ngModel)]="moderator_institution">
      </mat-form-field>
      <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Localidad</mat-label>
        <input  type="text"
                matInput
                name="location"
                required
                [(ngModel)]="moderator_location">
      </mat-form-field>
      <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Sobreprecio (€)</mat-label>
        <input  type="number"
                matInput
                name="overprice"
                [(ngModel)]="moderator_overprice">
      </mat-form-field>
      <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Email</mat-label>
        <input  type="email"
                matInput
                name="email"
                required
                email
                [(ngModel)]="moderator_email">
      </mat-form-field>
      <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Número de telefono</mat-label>
        <input  type="text"
                matInput
                name="phone"
                pattern="^[0-9]*$"
                minlength="9"
                required
                [(ngModel)]="moderator_phonenumber">
      </mat-form-field>
      <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Contraseña</mat-label>
        <input  type="password"
                matInput
                name="password"
                [type]="show_button ? 'text' : 'password'"
                required
                [(ngModel)]="moderator_password">
                <mat-icon class="icon" slot="end" style='color:#0099ff' [class]="show_eye ? 'fa fa-eye' : 'fa fa-eye-slash'" (click)="showPassword()"></mat-icon>
      </mat-form-field>
      <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Repetir Contraseña</mat-label>
        <input  type="password"
                matInput
                name="password_r"
                [type]="show_button1 ? 'text' : 'password'"
                required
                [(ngModel)]="repeat_password">
                <mat-icon class="icon" slot="end" style='color:#0099ff' [class]="show_eye1 ? 'fa fa-eye' : 'fa fa-eye-slash'" (click)="showPassword1()"></mat-icon>
      </mat-form-field>
      <button mat-flat-button color="primary">Registrar coordinador</button>
    </form>
  </mat-card-content>
</mat-card>
