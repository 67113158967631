<mat-card class="col-lg-6 col-md-12 col-sm-12 mx-auto mt-5 fade-in proviaje-last-item">
  <mat-card-header >
      <mat-card-title >Administrar Categoría</mat-card-title>
  </mat-card-header>
  <mat-card-content>
      <form #categoryForm="ngForm"
          (ngSubmit)="updateCategory()">

      <mat-form-field class="d-block col-12 col-md-8 col-lg-6 m-auto">
          <mat-label>Nombre de la categoría</mat-label>
          <input  required
                  matInput
                  name="name"
                  [(ngModel)]="category_name">
      </mat-form-field>
      
      <button class="d-block mx-auto mt-4"
              mat-flat-button
              color="primary">Modificar</button>
      </form>
  </mat-card-content>
</mat-card>
<section class="col-12 mt-2 mb-3">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Productos asociados</mat-card-title>
      </mat-card-header>
      <mat-card-content class="margeninf">
        <div class="table-container">
          <app-table-product [productList]="productData" tableConfig="category"></app-table-product>
        </div>
      </mat-card-content>
    </mat-card>
</section>
  
