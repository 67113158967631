<section class="mt-2 col-12 fade-in proviaje-last-item">
    <mat-card class="col-lg-6 col-md-12 col-sm-12 mx-auto my-5 fade-in" *ngIf="showRegisterCategory">
      <mat-card-header>
        <mat-card-title>
          Registrar categoría
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-form-field class="d-block m-3">
          <mat-label>Nombre de la categoría</mat-label>
          <input [(ngModel)]="category_name"
          [ngModelOptions]="{standalone: true}" matInput>
        </mat-form-field>
        <div class="text-center m-3">
          <button class="ml-auto mr-2 mb-2" mat-flat-button color="warn" (click)="showRegisterNewCategory()">Cancelar</button>
          <button class="mr-auto ml-2" mat-flat-button color="primary" (click)="registerCategory()">Registrar</button>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          Categorías disponibles
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="text-center">
        <div class="table-container">
          <app-table-categorys  (deleteEvent)="deleteCategoryEvent($event)" [userType]="userType" [categoryList]="categoryList"></app-table-categorys>
        </div>
        <button mat-raised-button color="primary" class="my-3" (click)="showRegisterNewCategory()">Registrar categoría</button>
      </mat-card-content>
    </mat-card>
</section>
