<div class="text-center ">
  <h2 *ngIf="requestList.length < 1">No hay pedidos cargados</h2>
  <table mat-table #prueba="matSort" *ngIf="requestList.length > 0" [dataSource]="requestDataSource" matSort>

    <ng-container matColumnDef="user_name" sticky *ngIf="showUser == true">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Alumno </th>
      <td mat-cell *matCellDef="let request; let i = index" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ sellerNameList[i] }} </td>
    </ng-container>

    <ng-container matColumnDef="user_name" *ngIf="showUser == false">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Alumno </th>
      <td mat-cell *matCellDef="let request; let i = index" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ sellerNameList[i] }} </td>
    </ng-container>

    <ng-container matColumnDef="course_name" sticky *ngIf="showCourse == true">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Curso </th>
      <td mat-cell *matCellDef="let request" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ request['grade_name'] }}  </td>
    </ng-container>

    <ng-container matColumnDef="course_name" *ngIf="showCourse == false">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Curso </th>
      <td mat-cell *matCellDef="let request" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ request['grade_name'] }}  </td>
    </ng-container>

    <ng-container matColumnDef="request_modification" sticky *ngIf="showSellerProfit == true  && showUser == false">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Fecha de pedido </th>
      <td mat-cell *matCellDef="let request" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ request.request_modification | date }} </td>
    </ng-container>

    <ng-container matColumnDef="request_modification" *ngIf="showSellerProfit == true  && showUser == true">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Fecha de pedido </th>
      <td mat-cell *matCellDef="let request" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ request.request_modification | date }} </td>
    </ng-container>

    <ng-container matColumnDef="request_modification" *ngIf="showSellerProfit == false  && showUser == false">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Fecha de compra </th>
      <td mat-cell *matCellDef="let request" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ request.request_date != null ? (request.request_date | date) : 'Pedido no tramitado' }} </td>
    </ng-container>

    <ng-container matColumnDef="request_total">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Total precio venta </th>
      <td mat-cell *matCellDef="let request" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ request.request_total | currency : "EUR" : "symbol" }} </td>
    </ng-container>

    <ng-container matColumnDef="request_totalprofit">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Total precio costo </th>
      <td mat-cell *matCellDef="let request" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ request.request_totalprofit | currency : "EUR" : "symbol" }} </td>
    </ng-container>

    <ng-container matColumnDef="request_total_seller_profit">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Total beneficio </th>
      <td mat-cell *matCellDef="let request" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ (request.request_total - request.request_totalprofit) | currency : "EUR" : "symbol" }} </td>
    </ng-container>

    <ng-container matColumnDef="action" >
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-1]="paddingColumnsResponsive == true" [class.pl-1]="paddingColumnsResponsive == true"> Opciones </th>
      <td mat-cell *matCellDef="let request" [class.pr-1]="paddingColumnsResponsive == true" [class.pl-1]="paddingColumnsResponsive == true">
        <div class="mx-auto" [class.custom-column-px]="paddingColumnsResponsive == true">
          <button mat-raised-button color="primary"
                [routerLink]="['/' + userType, 'order-detail', request.request_id]"
                class="">Detalles
          </button>
          <button mat-raised-button color="primary"
                  [routerLink]="['/' + userType, 'order', request.request_id]"
                  class="mx-2"
                  *ngIf="userType == 'admin'"
                  matTooltip="Editar pedido"><mat-icon>edit</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>

  </table>

  <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
</div>
