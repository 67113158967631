import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DataMasterService } from './../../services/data-master.service';

import { Product } from './../../classes/product.class';

import Swal from 'sweetalert2'

@Component({
  selector: 'app-catalogue-detail',
  templateUrl: './catalogue-detail.component.html',
  styleUrls: ['./catalogue-detail.component.scss']
})
export class CatalogueDetailComponent implements OnInit {

  product : Product;
  actualYear = new Date().getFullYear();

  constructor(private dataService : DataMasterService,
    private route : ActivatedRoute) {}

  ngOnInit() : void {
    this.dataService.setSideNav(false);
    window.scroll(0,0);
    this.route.params.subscribe(params => {
      const productId = params.id;
      this.loadProductData(productId);
    });
  }

  loadProductData(productId) {
    this.dataService.getProduct(productId).subscribe(response => {
      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      this.product = response[1][0];
      this.product.product_description = this.product.product_description.replace(/\n/g, "<br />");
    });
  }

}
