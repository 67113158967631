<div class="row fade-in proviaje-last-item">
  <section class="col-12">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          Historial de pedidos
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="table-container">
          <app-table-order [showSellerProfit]="true" [userType]="typeUser" [requestList]="requestList"></app-table-order>
        </div>
      </mat-card-content>
    </mat-card>
  </section>
<div>
