import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataMasterService, USER_TYPE_USER, USER_TYPE_MOD, USER_TYPE_ADMIN } from 'src/app/services/data-master.service';
import { Order } from '../../classes/order.class';
import { Grade } from '../../classes/grade.class';
import { Request } from '../../classes/request.class';
import { Profit } from '../../classes/profit.class';
import { Seller } from '../../classes/seller.class';
import { Product } from '../../classes/product.class';
import { Moderator } from '../../classes/moderator.class';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  readonly typeUser = USER_TYPE_USER;
  readonly typeMod = USER_TYPE_MOD;
  readonly typeAdmin = USER_TYPE_ADMIN;

  order : boolean = true;
  userType : string;
  orderAdminList : Order[] = [];
  requestModList : Request[] = [];
  requestUserList: Request[] = [];
  user_profit : Profit[] = [];
  seller : Seller[] = [];
  moderators : Moderator[] = [];

  productAdminList: Product[]=[];

  constructor(public dataService : DataMasterService ,private activatedRoute: ActivatedRoute) {
    this.dataService.setFooter("fixed");
    this.activatedRoute.params.subscribe(params =>{
      const loggedInUserType = dataService.getUserType();
      if (params.type != loggedInUserType) {
        this.dataService.showMessage('Error en la carga de inicio', '', 'error');
      } else {
        this.userType = loggedInUserType;
        this.loadData(this.userType);
      }
    });
    
  }

  ngOnInit(): void {
  }

  private loadData(userType : string) {
    switch (userType) {
      case this.typeUser: {
        this.loadUserData();
        break;
      }
      case this.typeMod: {
        this.loadModData();
        break;
      }
      case this.typeAdmin: {
        this.loadAdminData();
        break;
      }
      default: {
        break;
      }
    }
  }

  private loadUserData() {
    this.dataService.getSeller(parseFloat(this.dataService.getUserId()))
      .subscribe(responseUserData => {

      if (!responseUserData[0]) {
        //console.error(responseUserData[1]);
        this.dataService.showMessage('Error inesperado', 'Ha ocurrido un error inesperado en la base de datos.', 'error');
        return;
      }

      this.seller = responseUserData[1];
    });

    //console.log(this.dataService.getUserId());


    this.dataService.getProfit(parseFloat(this.dataService.getUserId()))
      .subscribe(responseProfit => {

      if (!responseProfit[0]) {
        //console.error(responseProfit[1]);
        this.dataService.showMessage('Error inesperado', 'Ha ocurrido un error inesperado en la base de datos.', 'error');
        return;
      }

      this.user_profit = responseProfit[1];
      //console.log(this.user_profit);
    });
  }

  private loadModData() {
    const modId = this.dataService.getUserId();
    this.dataService.getRequestByModerator(parseInt(modId, 10)).subscribe(responseRequestSellers =>{

      if (!responseRequestSellers[0]) {
        //console.error(responseRequestSellers[1]);
        this.dataService.showMessage('Error inesperado', 'Ha ocurrido un error inesperado en la base de datos.', 'error');
        return;
      }

      if (responseRequestSellers[1] == "no result") {
      } else {
        //console.log(responseRequestSellers);
        this.requestModList = responseRequestSellers[1];
        this.requestModList.splice(10);

        this.requestModList.forEach((request, index) => {
          //console.log(request);

          this.dataService.getGradeBySellerId(request.seller_id).subscribe(response => {
            if (!response[0]) {
              console.error(response[1]);
              Swal.close();
              this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
              return;
            }

            //console.log(response[1]);


            this.requestModList[index]['grade_name'] = response[1][0]['grade_name'];
            //console.log(this.requestModList);

          });
        });
      }

    });
  }

  loadInstitutions() {
    this.dataService.getAllModerators().subscribe(response => {
      if (!response[0]) {
        //console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      this.moderators = response[1];
      this.loadOrders();
    });
  }

  public loadOrders() : void {
    this.dataService.getAllOrders().subscribe(response => {
      Swal.close();
      if (!response[0]) {
        //console.error(response[1]);
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      if (response[1] == "no result") {
      } else {
        this.orderAdminList = response[1];
        this.orderAdminList.splice(10);
        this.orderAdminList.forEach((order, index) => {
          this.loadGradeData(index);
        });
      }
    });
  }

  public loadGradeData(index : number) : void {
    this.dataService.getGradeId(this.orderAdminList[index].grade_id).subscribe(response => {
      if (!response[0]) {
        //console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }


      if (Array.isArray(response[1]) && response[1].length > 0) {
        //console.log(response);
        this.orderAdminList[index].orders_grade = response[1][0].grade_name;
        const moderator = this.moderators.filter(item => item.moderator_id == response[1][0].moderator_id)[0];
        this.orderAdminList[index].orders_institution = moderator.moderator_institution;
      }

    });
  }

  private loadAdminData() {
    this.loadInstitutions();
    // this.dataService.getAllOrders().subscribe(responseOrdersAdmin =>{
    //
    //   if (!responseOrdersAdmin[0]) {
    //     console.error(responseOrdersAdmin[1]);
    //     this.dataService.showMessage('Error inesperado', 'Ha ocurrido un error inesperado en la base de datos.', 'error');
    //     return;
    //   }
    //
    //   if (responseOrdersAdmin[1] == "no result") {
    //   } else {
    //     this.orderAdminList = responseOrdersAdmin[1].reverse();
    //     this.orderAdminList.splice(5);
    //   }
    // });

    this.dataService.getAllProducts().subscribe(responseAdminProducts =>{
      //console.log(responseAdminProducts);
      if (!responseAdminProducts[0]) {
        //console.error(responseAdminProducts[1]);
        this.dataService.showMessage('Error inesperado', 'Ha ocurrido un error inesperado en la base de datos.', 'error');
        return;
      }

      if (responseAdminProducts[1] == "no result") {
      } else {
        this.productAdminList = responseAdminProducts[1];
        this.productAdminList = this.productAdminList.map(item => {
          item.product_quantity = +item.product_quantity;
          item.product_treshold = +item.product_treshold;

          return item;
        });

        this.productAdminList = this.productAdminList.filter(productAdminList => productAdminList.product_quantity <= productAdminList.product_treshold);
      }
    });
  }

}
