<mat-card class="w-50 mx-auto mt-5 fade-in proviaje-last-item">
  <mat-card-header>
    <mat-card-title>Registrar alumno</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form class="my-3"
          #registerForm="ngForm"
          (ngSubmit)="regSeller()">
      <mat-form-field class="d-block col-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Seleccionar curso</mat-label>
        <mat-select [(ngModel)]="grade_id"
                    name="course">
          <mat-option *ngFor="let course of courseData"
                      [value]="course.grade_id">
            {{ course.grade_name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="d-block col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Nombre</mat-label>
        <input  type="text"
                matInput
                required
                name="name"
                [(ngModel)]="seller_name">
      </mat-form-field>
      <mat-form-field class="d-block col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Apellidos</mat-label>
        <input  type="text"
                matInput
                required
                name="lastname"
                [(ngModel)]="seller_lastname">
      </mat-form-field>
      <mat-form-field class="d-block col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Email</mat-label>
        <input  type="text"
                matInput
                required
                email
                name="email"
                type="email"
                [(ngModel)]="seller_email">
      </mat-form-field>
      <mat-form-field class="d-block col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Número de telefono</mat-label>
        <input  pattern="^[0-9]*$"
                matInput
                name="phone"
                minlength="9"
                [(ngModel)]="seller_phonenumber">
      </mat-form-field>
      <mat-form-field class="d-block col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Contraseña</mat-label>
        <input  type="password"
                matInput
                required
                name="password"
                [type]="show_button ? 'text' : 'password'"
                [(ngModel)]="seller_password">
                <mat-icon class="icon" slot="end" style='color:#0099ff' [class]="show_eye ? 'fa fa-eye' : 'fa fa-eye-slash'" (click)="showPassword()"></mat-icon>
      </mat-form-field>
      <mat-form-field class="d-block col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Repetir Contraseña</mat-label>
        <input  type="password"
                matInput
                required
                name="password_r"
                [type]="show_button1 ? 'text' : 'password'"
                [(ngModel)]="repeat_password">
                <mat-icon class="icon" slot="end" style='color:#0099ff' [class]="show_eye1 ? 'fa fa-eye' : 'fa fa-eye-slash'" (click)="showPassword1()"></mat-icon>
      </mat-form-field>
      <button mat-flat-button
              color="primary">Registrar</button>
    </form>
  </mat-card-content>
</mat-card>
