<mat-card class="col-lg-6 col-md-6 mx-auto p-3 fade-in"
          *ngIf="!recovering">
  <mat-card-content>
    <form>
      <img  class="option-logo plogo"
            src="assets/logo.png">
      <mat-card-title class="stext"><u>{{ titulo }}</u></mat-card-title>
      <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Correo</mat-label>
        <input  [(ngModel)]="email"
                matInput
                [ngModelOptions]="{ standalone: true }">
      </mat-form-field>
      <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Contraseña</mat-label>
        <input  [(ngModel)]="password"
                matInput
                type="password"
                [type]="show_button ? 'text' : 'password'"
                [ngModelOptions]="{standalone: true}">
                <mat-icon class="icon" slot="end" style='color:#0099ff' [class]="show_eye ? 'fa fa-eye' : 'fa fa-eye-slash'" (click)="showPassword()"></mat-icon>
      </mat-form-field>
      <p  class="my-4"
          *ngIf="userType != 'admin'">
        <a  href="/"
            (click)="openRecover($event)">¿Olvidaste tu contraseña?</a>
      </p>
      <button *ngIf="userType != 'admin'"
              type="button"
              [routerLink]="['/options']"
              class="mx-2 my-3"
              mat-button
              color="warn"><mat-icon class="mx-2">keyboard_arrow_left</mat-icon>Atras</button>
      <button (click)="login()"
              mat-flat-button
              class="mx-2 my-3"
              color="primary">Iniciar Sesión</button>
    </form>
  </mat-card-content>
</mat-card>
<mat-card class="col-lg-6 col-md-6 mx-auto p-3 fade-in"
          *ngIf="recovering">
  <mat-card-content>
    <form [formGroup]="recoverGroup"
          (ngSubmit)="sendRecover()">
      <img  class="option-logo"
            src="assets/logo.png">
      <mat-card-title>Recuperar contraseña</mat-card-title>
      <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Correo</mat-label>
        <input  matInput
                formControlName="email">
      </mat-form-field>
      <button *ngIf="userType != 'admin'"
              type="button"
              (click)="recovering = false"
              class="mx-2 my-3"
              mat-button
              color="warn"><mat-icon class="mx-2">keyboard_arrow_left</mat-icon>Atras</button>
      <button type="submit"
              mat-flat-button
              class="mx-2 my-3"
              color="primary">Recuperar contraseña</button>
    </form>
  </mat-card-content>
</mat-card>
