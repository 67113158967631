import { Component, OnInit, ViewChild } from '@angular/core';
import { DataMasterService, USER_TYPE_USER, USER_TYPE_MOD, USER_TYPE_ADMIN } from './../../services/data-master.service';

import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  readonly typeUser = USER_TYPE_USER;
  readonly typeMod = USER_TYPE_MOD;
  readonly typeAdmin = USER_TYPE_ADMIN;

  name : string = '';
  moderator_id : string = '';
  moderator_institution : string = '';
  phoneNumber = '';
  email = '';
  actualYear = new Date().getFullYear();
  public sideNavMode : string = 'side';
  @ViewChild('sidenav') sidenav:any;

  constructor( public dataService : DataMasterService ,private activatedRoute: ActivatedRoute , private router: Router) {
    let userId = '';
    let userType = '';
    
    //console.log(router);
    /*this.activatedRoute.params.subscribe(params =>{
      console.log("i");
      console.log(params);
    });*/

    this.dataService.userIdChange.subscribe(response => {
      userId = response;
      if (userId.length > 0) {
        this.loadUserData(userId, userType);
      }
    });

    this.dataService.userTypeChange.subscribe(response => {
      userType = response;
      if (userId.length > 0) {
        this.loadUserData(userId, userType);
      }
    });

    this.dataService.getContact().subscribe(response =>{
      //console.log(response);
      this.phoneNumber = response[1][0].phone_number;
      this.email = response[1][0].email;
    });
  }

  ngOnInit(): void {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.sideNavMode = 'over'; 
    }else{
      this.sideNavMode = 'side'; 
    }
  }

  private loadUserData(userId, userType) {
    switch (userType) {
      case USER_TYPE_USER: {
        this.loadSeller(userId);
        break;
      }
      case USER_TYPE_MOD: {
        this.loadModerator(userId);
        break;
      }
      case USER_TYPE_ADMIN: {
        this.loadAdmin(userId);
        break;
      }
      default: {
        break;
      }
    }
  }

  private loadSeller(sellerId) {
    this.dataService.getSeller(sellerId).subscribe(response => {
      console.log(response);

      if (!response[0]) {
        Swal.close();
        this.dataService.showMessage("Error inesperado", "Ha ocurrido un error inesperado en la base de datos.", "error");
        return;
      } else if (!Array.isArray(response[1]) || response[1].length < 1) {
        Swal.close();
        this.dataService.showMessage("Error inesperado", "Error en la carga de datos del usuario.", "error");
        return;
      }

      this.name = `${response[1][0].seller_name} ${response[1][0].seller_lastname}`;
    });
  }

  private loadModerator(moderatorId) {
    this.dataService.getModerator(moderatorId).subscribe(response => {
      console.log(response);

      if (!response[0]) {
        Swal.close();
        this.dataService.showMessage("Error inesperado", "Ha ocurrido un error inesperado en la base de datos.", "error");
        return;
      } else if (!Array.isArray(response[1]) || response[1].length < 1) {
        Swal.close();
        this.dataService.showMessage("Error inesperado", "Error en la carga de datos del usuario.", "error");
        return;
      }

      this.name = `${response[1][0].moderator_name} ${response[1][0].moderator_lastname}`;
      this.moderator_id = `${response[1][0].moderator_id}`;
      this.moderator_institution = response[1][0].moderator_institution;
    });
  }
  private loadAdmin(adminId) {
    this.name = `Administrador`;
  }

  logout() {
    this.name = "";
    this.moderator_id = "";
    this.dataService.setUserId('');
    this.dataService.setUserType('');
    sessionStorage.removeItem('session_token');
    sessionStorage.removeItem('cart_list');
    sessionStorage.removeItem('cart_quantity_list');
    sessionStorage.removeItem('moderator_request_list');
    this.dataService.setSideNav(false);
  }

}
