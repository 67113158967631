<mat-card *ngIf="moderatorData != null" class="fade-in col-lg-6 col-md-6 mx-auto my-5">
  <mat-card-header class="secondary">
    <mat-card-title>Datos del coordinador</mat-card-title>
  </mat-card-header>
  <mat-card-content class="lead">
    <div class="text-center m-3">
      <span>Nombre de coordinador:</span>
      <span class="text-bold">
        {{ moderatorData.moderator_name }}
      </span>
    </div>
    <div class="text-center m-3">
      <span>Centro de estudio:</span>
      <span class="text-bold">
        {{ moderatorData.moderator_institution }}
      </span>
    </div>
  </mat-card-content>
</mat-card>
<mat-card *ngIf="moderatorData == null" class="fade-in col-lg-6 col-md-6 mx-auto my-5">
  <mat-card-header class="secondary">
    <mat-card-title>Datos del coordinador</mat-card-title>
  </mat-card-header>
  <mat-card-content class="lead">
    <div class="text-center m-3">
      <span>{{moderator_text}}</span>
    </div>
  </mat-card-content>
</mat-card>
<mat-card class="fade-in col-lg-6 col-md-6 mx-auto my-5">
  <mat-card-header>
    <mat-card-title>
      Autoregistro del Alumno
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="p-3">
    <mat-horizontal-stepper linear>
      <mat-step label="Codigo de coordinador"
                [stepControl]="firstFormGroup"
                [optional]="false">
        <form [formGroup]="firstFormGroup">
          <mat-form-field class="d-block col-sm-12 col-md-8 col-lg-6 m-auto">
            <mat-label>Ingresa el código facilitado por el coordinador:</mat-label>
            <input  type="number"
                    matInput
                    required
                    [(ngModel)]="moderator_id"
                    formControlName="codeCtrl">
            <mat-error *ngIf="firstFormGroup.get('codeCtrl').hasError('required')">
              El campo esta vacio.
            </mat-error>
            <mat-error *ngIf="firstFormGroup.get('codeCtrl').hasError('codeNumberError')">
              El valor introducido debe ser un numero.
            </mat-error>
            <mat-error *ngIf="firstFormGroup.get('codeCtrl').hasError('codeExistError')">
              No hay cursos dados de alta para este coordinador.
            </mat-error>
          </mat-form-field>
          <button (click)="backLocation()"
                  class="mx-2 my-3"
                  type="button"
                  mat-button
                  color="warn"><mat-icon class="mx-2">keyboard_arrow_left</mat-icon>Atras</button>
          <button class="mx-2 my-3"
                  mat-flat-button
                  [disabled]="firstFormGroup.invalid && firstFormGroup.status !== 'VALID'"
                  matStepperNext
                  type="button"
                  color="primary">Continuar</button>
        </form>
      </mat-step>

      <mat-step label="Datos del Alumno"
                [optional]="false"
                [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup"
              (ngSubmit)="registro()">
          <mat-form-field class="d-block">
            <mat-label>Nombre</mat-label>
            <input  matInput
                    [(ngModel)]="seller_name"
                    formControlName="nameCtrl">
          </mat-form-field>
          <mat-form-field class="d-block">
            <mat-label>Apellidos</mat-label>
            <input  matInput
                    [(ngModel)]="seller_lastname"
                    formControlName="lastnameCtrl">
          </mat-form-field>
          <mat-form-field class="d-block">
            <mat-label>Correo</mat-label>
            <input  matInput
                    [(ngModel)]="seller_email"
                    formControlName="emailCtrl">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Selecciona tu curso</mat-label>
            <mat-select [(ngModel)]="grade_id"
                        formControlName="courseCtrl">
              <mat-option *ngFor="let course of courseData"
                          [value]="course.grade_id">
                {{ course.grade_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="d-block">
            <mat-label>Número de telefono</mat-label>
            <input  matInput
                    type="text"
                    [(ngModel)]="seller_phonenumber"
                    formControlName="phoneCtrl">
          </mat-form-field>
          <mat-form-field class="d-block">
            <mat-label>Contraseña</mat-label>
            <input  matInput
                    type="password"
                    [type]="show_button ? 'text' : 'password'"
                    [(ngModel)]="seller_password"
                    formControlName="passCtrl">
                    <mat-icon class="icon" slot="end" style='color:#0099ff' [class]="show_eye ? 'fa fa-eye' : 'fa fa-eye-slash'" (click)="showPassword()"></mat-icon>
            <mat-error *ngIf="secondFormGroup.get('passCtrl').hasError('minlength')">
              La contraseña debe contener al menos 1 minúscula, 1 mayúscula, 1 número, y un tamaño mínimo de 8 carcateres.
            </mat-error>
          </mat-form-field>
          <mat-form-field class="d-block">
            <mat-label>Repetir contraseña</mat-label>
            <input  matInput
                    type="password"
                    [type]="show_button1 ? 'text' : 'password'"
                    [(ngModel)]="r_password"
                    formControlName="repPassCtrl">
                    <mat-icon class="icon" slot="end" style='color:#0099ff' [class]="show_eye1 ? 'fa fa-eye' : 'fa fa-eye-slash'" (click)="showPassword1()"></mat-icon>
            <mat-error *ngIf="secondFormGroup.get('repPassCtrl').hasError('minlength')">
              La contraseña debe contener al menos 1 minúscula, 1 mayúscula, 1 número, y un tamaño mínimo de 8 carcateres.
            </mat-error>
          </mat-form-field>
          <mat-checkbox formControlName="tos">Acepto los
            <a  href="assets/politica_privacidad.pdf"
                target="_blank">terminos y condiciones</a>
            y la
            <a  href="assets/proteccion_datos.pdf"
                target="_blank">politica de proteccion de datos</a>.</mat-checkbox>
          <mat-error *ngIf="secondFormGroup.get('tos').hasError('required') && secondFormGroup.get('tos').touched">Debe aceptar los terminos y condiciones.</mat-error>
          <button class="float-left mx-auto mt-4"
                  mat-button
                  color="warn"
                  matStepperPrevious><mat-icon class="mx-2">keyboard_arrow_left</mat-icon>Atras</button>
          <button class="d-block mx-auto mt-4"
                  mat-flat-button
                  color="primary">Registrar</button>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-card-content>
</mat-card>
